import React from "react";
import { Icons, Images } from "../../constants";
import Ratings from "./Ratings";
import CircleButton from "./CircleButton";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ item }) => {
  const navigate = useNavigate();
  const handleGo = (type = "") => {
    if (type === "course") navigate(`/courses?cat=${item.id}`);
    else navigate(`/products?cat=${item.id}`);
  };

  return (
    <div
      onClick={() => handleGo(item.name === "e-learning" && "course")}
      className="mt-4 group border hover:border-primary-100 hover:border-2 rounded-md border-gray-400 flex flex-col items-center justify-center h-[145px] w-full md:w-[180px] cursor-pointer"
    >
      <img
        src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.icon}`}
        alt={item.name}
        className="rounded-sm w-[56px] opacity-70"
      />
      <h3 className="group-hover:text-primary-100 capitalize text-center">
        {item.name}
      </h3>
    </div>
  );
};

export default CategoryCard;
