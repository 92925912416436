import React from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import { Images } from "../constants";
import { Link } from "react-router-dom";
import SignUpForm from "../components/form/SignUpForm";

const SignUp = () => {
  return (
    <div className="">
      <main className="md:grid grid-cols-3 relative">
        <div className="hidden md:block col-span-2 min-h-[600px]">
          <img
            src={Images.Sign}
            alt="image signup"
            className="col-span-8 pr-40 w-full"
          />
        </div>

        <div className="absolute left-0 top-0 bottom-0 right-0">
          <div className="responsive grid grid-cols-3 h-full">
            <div className="flex flex-col items-center justify-center col-start-3">
              <h1 className="w-full text-left first-letter:capitalize font-inter font-medium text-xl md:text-4xl">
                Creer un nouveau compte
              </h1>
              <p className="w-full text-left first-letter:capitalize mb-4 mt-4 text-sm md:text-base">
                Entrez vos coordonnées ci-dessous
              </p>
              <SignUpForm />
              <div className="flex items-center space-x-2 mt-2 text-xs md:text-sm">
                <p>Vous avez déjà un compte ?</p>
                <Link to="/login">
                  <p className="underline first-letter:capitalize font-medium">
                    Se connecter
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignUp;
