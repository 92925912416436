import React, { useRef } from "react";
import ProductPopUp from "./ProductPopUp";
import CoursePopUp from "./CoursePopUp";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const DetailsPopUp = ({
  onOpenCart,
  isOpen,
  onClose,
  clearData,
  productTypeSlice,
}) => {
  const modalContentRef = useRef();

  const scrollToTop = () => {
    modalContentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={clearData} size={"5xl"}>
          <ModalOverlay />
          <ModalContent css={{ padding: "0", overflow: "hidden" }}>
            <ModalCloseButton />
            <ModalBody
              css={{ padding: "0", overflow: "hidden" }}
              ref={modalContentRef}
            >
              {productTypeSlice === "product" ? (
                <ProductPopUp
                  onClose={onClose}
                  scrollToTop={scrollToTop}
                  clearData={clearData}
                  onOpen={onOpenCart}
                />
              ) : (
                <CoursePopUp
                  onClose={onClose}
                  scrollToTop={scrollToTop}
                  clearData={clearData}
                  onOpen={onOpenCart}
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default DetailsPopUp;
