import React, { forwardRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Autoplay,
  Grid,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/modules";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import BannerCard from "./BannerCard";
import { Icons, Images } from "../../constants";
import SocialMedia from "./SocialMedia";
import ProductPopUp from "./ProductPopUp";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../store/product/product-slice";

const SwiperCustom = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const handleOpen = (item) => {
    dispatch(productActions.replaceData({ item: item, type: "product" }));
  };

  if (!props.data) return <p>Loading...</p>;
  return (
    <div className="h-full">
      <Swiper
        style={{ height: "100%" }}
        ref={ref}
        cssMode={true}
        pagination={{
          clickable: true,
          enabled: !!props.pagination,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          enabled: !!props.autoplay,
        }}
        slidesPerView={1}
        spaceBetween={0}
        breakpoints={{
          // Define breakpoints and options for different screen sizes
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: props.slides,
            spaceBetween: 30,
          },
        }}
        // grid={{
        //   rows: 2,
        // }}
        mousewheel={true}
        keyboard={true}
        modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {props.data.map((item, idx) => (
          <SwiperSlide className="pb-2 !h-full" key={idx}>
            <props.card item={item} type={props.type} action={handleOpen} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* {isOpen && select && (
        <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
          <ModalOverlay />
          <ModalContent css={{ padding: "0", overflow: "hidden" }}>
            <ModalCloseButton />
            <ModalBody css={{ padding: "0", overflow: "hidden" }}>
              <ProductPopUp select={select} onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )} */}
    </div>
  );
});

export default SwiperCustom;
