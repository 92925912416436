import React, { useState } from "react";
import DevenirPartenaireForm from "../components/form/DevenirPartenaireForm";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import dataQuestions from "../data/questions.json";

const DevenirPartenairePage = () => {
  return (
    <div className="">
      <main className="responsive grid md:grid-cols-12 py-6 md:py-20 md:gap-x-16">
        <div className="col-span-6 order-2 md:order-1 mt-6 md:mt-0">
          <h1 className="capitalize text-sm md:text-xl font-medium mb-4 md:mb-10">
            Service de Dépôt-Vente d’Algérie Télécom
          </h1>
          <Accordion allowToggle>
            {dataQuestions.map((item, idx) => (
              <AccordionItem key={idx}>
                <h2>
                  <AccordionButton className="flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                      <div className="border rounded-full w-6 h-6 border-secondary-100 flex items-center justify-center">
                        <p className="text-xs">{idx + 1}</p>
                      </div>
                      <p className="text-left text-xs text-sm w-[80%]">
                        {item.qst}
                      </p>
                    </div>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.res }}
                    className="convertHTML md:text-sm text-xs"
                  />
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="col-span-6 order-1 md:order-2">
          <h1 className="capitalize text-sm md:text-xl font-medium">
            devenir partenaire
          </h1>
          <p className="text-gray-400 text-xs md:text-sm mb-4">
            [*] champs obligatoires
          </p>
          <DevenirPartenaireForm />
        </div>
      </main>
    </div>
  );
};

export default DevenirPartenairePage;
