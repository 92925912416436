import React, { useEffect, useRef, useState } from "react";
import { Icons, Images } from "../../constants";
import CardSection from "../section/CardSection";
import ProductCard from "./ProductCard";
import dataProducts from "../../data/products.json";
import SwiperCustom from "./SwiperCustom";
import BannerCard from "./BannerCard";
import { useDispatch, useSelector } from "react-redux";
import courseData from "../../data/partners.json";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Ratings from "./Ratings";
import { Link, useNavigate } from "react-router-dom";
import coursesService from "../../services/coursesService";
import { useQuery } from "react-query";
import basketService from "../../services/basketService";
import { cartActions } from "../../store/cart/cart-slice";
import { Spinner } from "@chakra-ui/react";

const CoursePopUp = ({ onClose, onOpen, scrollToTop, clearData }) => {
  const [loading, setLoading] = useState(false);
  const [inCart, isInCart] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const swiperImgs = useRef();
  const select = useSelector((slice) => slice.product.item);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    scrollToTop();
  }, [select]);

  if (!select) return "loading...";

  const handleAddToCart = () => {
    setLoading(true);
    // onOpen();
    basketService
      .addItem(user.token, {
        type: "course",
        item: select.id,
      })
      .then((res) => {
        dispatch(cartActions.addData(res));
        clearData();
        setLoading(false);
        onOpen();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleMoreInfo = (id) => {
    onClose();
    navigate(`/courses/${id}`);
  };

  return (
    <div>
      {/* detail product  */}
      <div className="flex flex-col md:grid md:grid-cols-12 gap-x-4 border-b">
        <div className="col-span-7 h-[500px]">
          {select && select.images && select.images.length > 0 && (
            <img
              src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${select.images[0]}`}
              alt={""}
              className="w-full h-full object-cover"
            />
          )}
        </div>
        {/* start details  */}
        <div className="bg-gray-100 h-full col-span-5 px-4 pt-4 md:pt-8 pb-4 flex flex-col justify-between">
          {/* start general information  */}
          <div>
            <h1 className="capitalize text-lg md:text-2xl font-bold">
              {select.name}
            </h1>
            <h3 className="capitalize mb-2">
              E-learning / {select.category.name}
            </h3>
            <div className="items-center flex space-x-3">
              <Ratings nbr={select.rate} />
              <p className="text-xs text-gray-400">
                ({select.reviews} commentaires)
              </p>
            </div>
            <div className="flex items-center space-x-2 mt-2 justify-between">
              {/* <p className="capitalize mb-4 text-sm">price:</p> */}
              <div className="space-x-2 flex items-center">
                <p
                  className={`${
                    select.discount > 0
                      ? "line-through text-gray-400"
                      : "text-third-100"
                  } font-medium text-sm`}
                >
                  {select.price} DZD
                </p>
                {select.discount > 0 && (
                  <p className="text-third-100 font-medium text-sm">
                    {(1 - select.discount / 100) * select.price} DZD
                  </p>
                )}
              </div>
              <img src={Icons.Love} alt="love icon" />
            </div>
            {/* start chapters  */}
            <h4 className="font-medium mt-6 text-gray-500 text-sm">
              Description
            </h4>
            <div className="bg-gray-600 text-gray-100 rounded-lg px-4 py-3 mt-3">
              <p className="text-xs">{select.description}</p>
            </div>
          </div>
          {/* buttons  */}
          <div className="w-full grid md:grid-cols-2 gap-x-4 text-sm">
            <button
              onClick={(id) => handleMoreInfo(select.id)}
              className="w-full bg-secondary-100 text-center text-white rounded-lg mt-6 mx-auto capitalize px-4 py-3"
            >
              <div className="flex items-center space-x-2 justify-center">
                <p>more information</p>
                <img src={Icons.Info} alt="" className="w-6" />
              </div>
            </button>
            <button
              disabled={loading}
              onClick={handleAddToCart}
              className="w-full bg-primary-100 text-white rounded-lg mt-6 mx-auto capitalize py-3"
            >
              {loading ? (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              ) : (
                <div className="flex items-center space-x-2 justify-center">
                  <p>Ajouter au panier</p>
                  <img src={Icons.CartWhite} alt="" className="w-6" />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* more details  */}

      {/* similar product */}
      {/* <div className="mx-10">
        <CardSection
          subTitle={""}
          title={"Similar Courses"}
          color={"text-primary-100"}
          bgColor={"bg-primary-100"}
          data={dataSimilar}
          cssContainer={"py-10"}
          card={({ item }) => {
            return (
              <div className="h-full w-full bg-green-700 rounded-lg cursor-pointer">
                <img
                  src={Images[item.img]}
                  alt={item.title}
                  className="w-full h-full object-cover"
                />
              </div>
            );
          }}
          slides={6}
          hide
        />
      </div> */}
    </div>
  );
};

export default CoursePopUp;
