import { createSlice } from "@reduxjs/toolkit";

const commentSlice = createSlice({
  name: "comment",
  initialState: {
    item: [],
  },
  reducers: {
    replaceData(state, action) {
      state.item = action.payload;
    },
    addData(state, action) {
      state.item = [action.payload, ...state.item];
    },

    clearData(state, action) {
      state.item = [];
    },
  },
});

export const commentActions = commentSlice.actions;

export default commentSlice;
