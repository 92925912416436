import { configureStore } from "@reduxjs/toolkit";
import productSlice from "./product/product-slice";
import cartSlice from "./cart/cart-slice";
import authSlice from "./auth/auth-slice";
import filterSlice from "./filter/filter-slice";
import commentSlice from "./product/comment-slice";

const store = configureStore({
  reducer: {
    product: productSlice.reducer,
    cart: cartSlice.reducer,
    auth: authSlice.reducer,
    filter: filterSlice.reducer,
    comment: commentSlice.reducer,
  },
});

export default store;
