import React, { useState } from "react";
import { Icons } from "../../constants";
import { useForm } from "react-hook-form";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth/auth-slice";

const LoginForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setError("");
    setLoading(true);
    authService
      .login(data)
      .then((res) => {
        if (res.error) {
          setError(res.message);
          setLoading(false);
        } else {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res.data, token: res.access_token })
          );
          dispatch(authActions.login({ ...res.data, token: res.access_token }));
          setLoading(false);
          navigate("/");
          reset();
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} method="POST">
      {error && (
        <p className="mb-4 -mt-4 text-third-100 bg-third-100 bg-opacity-10 border border-third-100 text-sm rounded px-2">
          <span className="font-medium">Error:</span> {error}
        </p>
      )}
      <input
        type="text"
        name="text"
        {...register("emailphone", { required: true })}
        id=""
        placeholder="Email or Phone Number"
        className="mb-3 block w-full border rounded-lg py-2 px-4 border-gray-300 outline-none text-xs md:text-base"
      />
      <input
        type="password"
        name="password"
        id=""
        {...register("password", { required: true })}
        placeholder="Password"
        className="mb-6 block w-full border rounded-lg py-2 px-4 border-gray-300 outline-none text-xs md:text-base"
      />

      <div className="flex items-center justify-between">
        <div>
          {loading ? (
            <div className="bg-third-100 py-2 px-10 rounded-lg flex items-center justify-center">
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </div>
          ) : (
            <input
              type="submit"
              value="Log In"
              className="text-white capitalize font-medium bg-third-100 py-2.5 px-10 cursor-pointer text-sm md:text-base rounded-lg"
            />
          )}
        </div>
        <p className="text-third-100 cursor-pointer text-xs md:text-sm">
          Forget Password?
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
