import Banner1 from "../assets/img/banner.png";
import Banner2 from "../assets/img/banner_2.png";
import Prod1 from "../assets/img/prod_1.png";
import Prod2 from "../assets/img/prod_2.png";
import Prod3 from "../assets/img/prod_3.png";
import Prod4 from "../assets/img/prod_4.png";
import ProdPartner1 from "../assets/img/prod_partner_1.png";
import ProdPartner2 from "../assets/img/prod_partner_2.png";
import ProdPartner3 from "../assets/img/prod_partner_3.png";
import ProdPartner4 from "../assets/img/prod_partner_4.png";
import ProdPartner5 from "../assets/img/prod_partner_4.png";
import ProdPartner6 from "../assets/img/prod_partner_5.png";
import QrCode from "../assets/img/Qrcode.png";
import BannerPub1 from "../assets/img/banner-pub-1.png";
import BannerPub2 from "../assets/img/banner-pub-2.png";
import BannerPub3 from "../assets/img/banner-pub-3.png";
import BannerPub4 from "../assets/img/banner-pub-4.png";
import BannerPub5 from "../assets/img/banner-pub-5.jpg";
import BannerPub6 from "../assets/img/banner-pub-6.jpg";
import BannerPub7 from "../assets/img/banner-pub-7.jpeg";
import BannerPub8 from "../assets/img/banner-pub-8.jpg";
import Sign from "../assets/img/sign.png";
import NoData from "../assets/img/nodata.png";

export default {
  Banner1,
  NoData,
  Banner2,
  Prod1,
  Prod2,
  Prod3,
  Prod4,
  ProdPartner1,
  ProdPartner2,
  ProdPartner3,
  ProdPartner4,
  ProdPartner5,
  ProdPartner6,
  QrCode,
  BannerPub1,
  BannerPub2,
  BannerPub3,
  BannerPub4,
  BannerPub5,
  BannerPub6,
  BannerPub7,
  BannerPub8,
  Sign,
};
