import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    query: "",
    cat: "",
  },
  reducers: {
    setQuery(state, action) {
      state.query = action.payload;
    },
    setCat(state, action) {
      state.cat = action.payload;
    },
    clearQuery(state, action) {
      state.query = "";
    },
    clearData(state, action) {
      state.cat = "";
      state.query = "";
    },
  },
});

export const filterActions = filterSlice.actions;

export default filterSlice;
