// import Logo from "../assets/logo/logo.png";
import Logo from "../assets/logo/logo-iboutique.png";
import Logo2 from "../assets/logo/logo-iboutique (1).png";
import LogoName from "../assets/logo/logo-name.png";
import LogoIcon from "../assets/logo/logo-icon2.png";

export default {
  Logo,
  Logo2,
  LogoName,
  LogoIcon,
};
