import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import CardSection from "../components/section/CardSection";
import ProductCard from "../components/ui/ProductCard";
import { Images } from "../constants";
import CategoryCard from "../components/ui/CategoryCard";
import SideBar from "../layout/SideBar";
import SwiperCustom from "../components/ui/SwiperCustom";
import dataProducts from "../data/products.json";
import dataPartners from "../data/partners.json";
import dataCategories from "../data/categories.json";
import dataBanners from "../data/banners.json";
import BannerCard from "../components/ui/BannerCard";
import { useEffect, useRef, useState } from "react";
import OverviewProductSection from "../components/section/OverviewProductSection";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ProductPopUp from "../components/ui/ProductPopUp";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../store/product/product-slice";
import CoursePopUp from "../components/ui/CoursePopUp";
import { useQuery } from "react-query";
import DetailsPopUp from "../components/ui/DetailsPopUp";
import categoryService from "../services/categoryService";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import coursesService from "../services/coursesService";
import productsService from "../services/productsService";
import bannerService from "../services/bannerService";
import CartDrawer from "../components/ui/CartDrawer";
import basketService from "../services/basketService";
import { cartActions } from "../store/cart/cart-slice";

const HomePage = () => {
  const swiperBanner = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [select, setSelect] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  const productTypeSlice = useSelector((slice) => slice.product.type);

  // to open pop up details
  useEffect(() => {
    if (productTypeSlice) onOpen();
  }, [productTypeSlice]);

  const clearData = () => {
    onClose();
    dispatch(productActions.replaceData({ item: null, type: "" }));
  };

  // get categories
  const {
    isLoading: isLoadingCategories,
    data: dataCategories,
    isError: isErrorCategories,
    error: errorCategories,
  } = useQuery("categories-parent", () =>
    categoryService.getAllCategoriesParent()
  );

  // get new courses
  const {
    isLoading: isLoadingCourse,
    data: dataCourse,
    isError: isErrorCourse,
    error: errorCourse,
  } = useQuery("new-courses", () => coursesService.getNews());

  // get all banners
  const {
    isLoading: isLoadingBanner,
    data: dataBanner,
    isError: isErrorBanner,
    error: errorBanner,
  } = useQuery("banners", () => bannerService.getAll());

  // get new products
  const {
    isLoading: isLoadingProduct,
    data: dataProduct,
    isError: isErrorProduct,
    error: errorProduct,
  } = useQuery("new-products", () => productsService.getNews());

  const user = useSelector((state) => state.auth.user);

  const {
    isLoading: isLoadingCart,
    data: dataCart,
    isError: isErrorCart,
    error: errorCart,
    isFetching: isFetchingCart,
    refetch: refetchCart,
  } = useQuery(
    "my-basket",
    () => {
      if (user) return basketService.get(user.token);
      else return [];
    },
    {
      onSuccess: (data) => {
        if (data && data.products && data.courses) {
          dispatch(
            cartActions.replaceData([...data.products, ...data.courses])
          );
        }
      },
    }
  );

  useEffect(() => {
    refetchCart();
  }, [user]);

  if (isErrorCategories) {
    return <div>Error: {errorCategories.message}</div>;
  }
  if (isErrorBanner) {
    return <div>Error: {errorBanner.message}</div>;
  }

  return (
    <div>
      <main className="responsive">
        <div className="grid grid-cols-12">
          <div className="col-span-3 pr-20 border-r pt-16 hidden md:block">
            <SideBar />
          </div>
          <div className="col-span-12 md:col-span-9 pt-4 md:pt-16 md:pl-10">
            {isLoadingBanner ? (
              <Skeleton width={"100%"} height={"100%"} />
            ) : (
              <SwiperCustom
                data={dataBanner}
                card={BannerCard}
                slides={1}
                ref={swiperBanner}
                pagination
                autoplay
              />
            )}
          </div>
        </div>

        <div className="border-b w-full"></div>

        {isLoadingProduct ? (
          <div className="my-10">
            <h3
              className={`w-fit mb-2 capitalize font-semibold text-xs md:text-sm h-fit mt-[5px] bg-gray-100 rounded-tr-full rounded-br-full px-2 py-[3px] text-primary-100`}
            >
              Notre produits
            </h3>
            <h1 className="font-semibold font-inter text-lg md:text-3xl mb-2">
              Nouveau produits ajoute
            </h1>
            <div className="grid grid-cols-4 gap-x-6">
              {[1, 2, 3, 4].map((item) => (
                <div key={item}>
                  <div className="shadow-md p-2 rounded-lg bg-gray-50 transition-all duration-500 hover:-translate-y-5 mt-6">
                    <div className="w-full md:w-full h-[280px] relative group z-0 bg-white rounded-xl overflow-hidden">
                      <Skeleton width={250} height={280} />
                    </div>
                    <div className="mt-4 bg-white p-2 rounded-xl z-20 relative">
                      <SkeletonText />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <CardSection
            subTitle={"Nos produits"}
            title={"Nouveaux produits ajoutés"}
            color={"text-primary-100"}
            bgColor={"bg-primary-100"}
            data={dataProduct}
            onClick={() => navigate("/products")}
            card={ProductCard}
          />
        )}

        <div className="border-b w-full"></div>

        {isLoadingCategories ? (
          <div className="my-10">
            <div className="flex items-center"></div>
            <div className="">
              <h3
                className={`w-fit mb-2 capitalize font-semibold text-xs md:text-sm h-fit mt-[5px] bg-gray-100 rounded-tr-full rounded-br-full px-2 py-[3px] text-primary-100`}
              >
                Categories
              </h3>
              <div className="flex items-start justify-between">
                <div className="flex justify-between space-x-4">
                  <h1 className="font-semibold font-inter text-lg md:text-3xl ">
                    Browse By Category
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-5 gap-x-6">
              {[1, 2, 3, 4, 5].map((item) => (
                <div key={item}>
                  <div className="h-fit w-full shadow-md p-2 rounded-lg bg-gray-50 mt-6">
                    <div className="h-fit w-full relative group z-0 bg-white rounded-xl overflow-hidden flex items-center justify-center flex-col space-y-2 py-4 px-4">
                      <Skeleton width={100} height={100} />
                      <Skeleton width={100} height={3} borderRadius={3} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <CardSection
            subTitle={"Categories"}
            title={"Parcourir par catégorie"}
            color={"text-primary-100"}
            bgColor={"bg-primary-100"}
            data={dataCategories}
            card={CategoryCard}
            slides={5}
            hide
          />
        )}
        <div className="border-b w-full"></div>

        {isLoadingProduct ? (
          <div className="my-10">
            <h3
              className={`w-fit mb-2 capitalize font-semibold text-xs md:text-sm h-fit mt-[5px] bg-gray-100 rounded-tr-full rounded-br-full px-2 py-[3px] text-primary-100`}
            >
              Notre produits
            </h3>
            <h1 className="font-semibold font-inter text-lg md:text-3xl mb-2">
              Meuilleur produits achete
            </h1>
            <div className="grid grid-cols-4 gap-x-6">
              {[1, 2, 3, 4].map((item) => (
                <div key={item}>
                  <div className="shadow-md p-2 rounded-lg bg-gray-50 transition-all duration-500 hover:-translate-y-5 mt-6">
                    <div className="w-full md:w-full h-[280px] relative group z-0 bg-white rounded-xl overflow-hidden">
                      <Skeleton width={250} height={280} />
                    </div>
                    <div className="mt-4 bg-white p-2 rounded-xl z-20 relative">
                      <SkeletonText />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <CardSection
            subTitle={"Nos produits"}
            title={"Meilleurs produits achetés"}
            color={"text-primary-100"}
            bgColor={"bg-primary-100"}
            data={dataProduct}
            onClick={() => navigate("/products")}
            card={ProductCard}
          />
        )}

        <div className="overflow-hidden rounded-xl">
          <img
            src={Images.Banner2}
            alt="banner pub idoom"
            className="w-full object-cover"
          />
        </div>

        {isLoadingCourse ? (
          <div className="my-10">
            <h3
              className={`w-fit mb-2 capitalize font-semibold text-xs md:text-sm h-fit mt-[5px] bg-gray-100 rounded-tr-full rounded-br-full px-2 py-[3px] text-secondary-100`}
            >
              Notre Courses
            </h3>
            <div className="flex items-start justify-between">
              <div className="">
                <h1 className="font-semibold font-inter text-lg md:text-3xl">
                  Decouvrir les nouveaux cours
                </h1>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-x-6">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <div key={item}>
                  <div className="shadow-md p-2 rounded-lg bg-gray-50 mt-6">
                    <div className="w-full md:w-full h-[280px] relative group z-0 bg-white rounded-xl overflow-hidden">
                      <Skeleton width={"100%"} height={"100%"} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <OverviewProductSection
            subTitle={"Nos courses"}
            title={"Découvrez nos nouveaux cours"}
            color={"text-secondary-100"}
            bgColor={"bg-secondary-100"}
            data={dataCourse}
            onClick={() => navigate("/courses")}
          />
        )}
      </main>

      <DetailsPopUp
        isOpen={isOpen}
        onClose={onClose}
        clearData={clearData}
        onOpenCart={onOpenCart}
        productTypeSlice={productTypeSlice}
      />
      {!isLoadingCart && (
        <CartDrawer
          isOpen={isOpenCart}
          onClose={onCloseCart}
          onOpen={onOpenCart}
        />
      )}
    </div>
  );
};

export default HomePage;
