import React from "react";
import Navbar from "../layout/Navbar";
import { Icons, Logos } from "../constants";
import Footer from "../layout/Footer";

const AboutPage = () => {
  return (
    <div>
      <div className="responsive my-20">
        <div className="grid items-center gap-6 grid-cols-2">
          <div className="flex items-center space-x-2 lg:order-last justify-center">
            <img
              alt="Company Logo"
              className="rounded-xl object-cover object-center sm:w-full md:w-[150px]"
              src={Logos.Logo2}
            />
            <img
              alt="Company Logo"
              className="rounded-xl object-cover object-center sm:w-full md:w-[300px]"
              src={Logos.Logo}
            />
          </div>
          <div className="flex flex-col justify-center space-y-10">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold">
                Welcome to Our Telecom Market
              </h2>
              <p className="max-w-[600px] text-gray-500 mt-10">
                We are a leading company in the procurement of routers for all
                types of telecommunication needs. Our mission is to provide
                high-quality routers and excellent service to our clients.
              </p>
            </div>
            <div className="space-y-2">
              <h2 className="text-3xl font-bold">Our Services</h2>
              <ul className="grid gap-2 py-4">
                <li className="flex items-center">
                  <img src={Icons.ArrowRight2} alt="" />
                  <p>
                    Providing high-quality routers for all types of
                    telecommunication needs.
                  </p>
                </li>
                <li className="flex items-center">
                  <img src={Icons.ArrowRight2} alt="" />
                  <p>
                    Customized procurement strategies to suit your individual or
                    business needs.
                  </p>
                </li>
                <li className="flex items-center">
                  <img src={Icons.ArrowRight2} alt="" />
                  <p>
                    Comprehensive customer support to ensure seamless service.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="space-y-2 mt-10">
          <h3 className="text-2xl font-bold">Our Approach to Procurement</h3>
          <p className="">
            We believe in a customer-centric approach to router procurement. We
            work closely with our clients to understand their specific needs and
            offer customized solutions. Our team of experts ensure the
            procurement process is smooth and efficient.
          </p>
          <p className="">
            We believe in a customer-centric approach to router procurement. We
            work closely with our clients to understand their specific needs and
            offer customized solutions. Our team of experts ensure the
            procurement process is smooth and efficient.
          </p>
          <p className="">
            We believe in a customer-centric approach to router procurement. We
            work closely with our clients to understand their specific needs and
            offer customized solutions. Our team of experts ensure the
            procurement process is smooth and efficient.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
