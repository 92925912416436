import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "carts",
  initialState: {
    item: null,
  },
  reducers: {
    replaceData(state, action) {
      state.item = action.payload;
    },
    addData(state, action) {
      if (!state.item) {
        state.item = [action.payload];
      } else {
        const find = state.item.find(
          (item) => item.productId === action.payload.productId
        );
        if (find)
          state.item = state.item.map((p) =>
            p.productId === action.payload.productId
              ? { ...p, amount: p.amount + 1 }
              : p
          );
        else state.item = [...state.item, action.payload];
      }
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;
