import React, { useState } from "react";
import { Icons } from "../../constants";
import { useForm } from "react-hook-form";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { authActions } from "../../store/auth/auth-slice";
import { useDispatch, useSelector } from "react-redux";

const SignUpForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setError("");
    setLoading(true);
    authService
      .register(data)
      .then((res) => {
        if (res.error) {
          setError(res.message);
          setLoading(false);
        } else {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res.data, token: res.access_token })
          );
          dispatch(authActions.login({ ...res.data, token: res.access_token }));

          setLoading(false);
          navigate("/");
          reset();
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} method="POST">
      {error && (
        <p className="bg-third-100 bg-opacity-20 text-third-100 border border-third-100 rounded text-sm px-2 py-px mb-2">
          <b>Error:</b> {error}
        </p>
      )}
      <input
        type="text"
        name="name"
        id=""
        placeholder="Votre nom"
        {...register("name", { required: true })}
        className="mb-4 block w-full border rounded-lg py-2 px-4 border-gray-300 outline-none text-xs md:text-base"
      />
      <input
        type="email"
        name="email"
        id=""
        placeholder="Votre email"
        {...register("email", { required: true })}
        className="mb-4 block w-full border rounded-lg py-2 px-4 border-gray-300 outline-none text-xs md:text-base"
      />
      <input
        type="tel"
        name="phone"
        id=""
        placeholder="Votre numero de telephone"
        {...register("phone", { required: true })}
        className="mb-4 block w-full border rounded-lg py-2 px-4 border-gray-300 outline-none text-xs md:text-base"
      />
      <input
        type="password"
        name="password"
        id=""
        placeholder="Saisir un mot de passe"
        {...register("password", { required: true })}
        className="mb-4 md:mb-6 block w-full border rounded-lg py-2 px-4 border-gray-300 outline-none text-xs md:text-base"
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      ) : (
        <div>
          <input
            type="submit"
            value="creer"
            className="text-white capitalize font-medium bg-third-100 rounded-xl py-2.5 w-full cursor-pointer mb-4 text-sm md:text-base"
          />
          {/* <button
            type="submit"
            className="flex items-center justify-center border rounded-xl py-2 md:py-4 w-full space-x-4"
          >
            <img src={Icons.Google} alt="icon google signup" />
            <p className="text-sm md:text-base">Sign up with Google</p>
          </button> */}
        </div>
      )}
    </form>
  );
};

export default SignUpForm;
