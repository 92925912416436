import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const filterParams = (filters) => {
  return Object.entries(filters)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

const getAllOrders = (token) => {
  return axios
    .get(`${url}/my-orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  getAllOrders,
};
