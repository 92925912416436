import React from "react";

const CircleButton = ({
  icon,
  name,
  onClick,
  bg = false,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`w-[30px] md:w-[35px] h-[30px] md:h-[35px] rounded-full ${bg} ${
        disabled && "opacity-40"
      } flex items-center justify-center`}
      disabled={disabled}
    >
      <img
        src={icon}
        alt={`icon ${name}`}
        className="w-[20px] md:w-[23px] h-[20px] md:h-[23px]"
      />
    </button>
  );
};

export default CircleButton;
