import React from "react";
import { Link, useParams } from "react-router-dom";
import authService from "../services/authService";
import { useQuery } from "react-query";
import { Spinner } from "@chakra-ui/react";

const ConfirmEmailPage = () => {
  const { token } = useParams();

  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "promotions",
    () => authService.verify(token)
  );

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="h-[400px]">
      <main className="flex flex-col items-center justify-center h-full bg-gray-50 ">
        {isLoading ? (
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : data.message === "Invalid token" ? (
          <div className="flex flex-col items-center space-y-4">
            <AlertCircleIcon className="h-16 w-16 text-red-500 dark:text-red-400" />
            <h1 className="text-3xl font-bold">Error!</h1>
            <p className="text-gray-500 dark:text-gray-400">
              There was an error while confirming your email. Please try again
              later or contact our support team.
            </p>
            <Link
              className="inline-flex items-center justify-center h-10 px-5 text-white bg-red-600 rounded-md hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-400"
              href="#"
            >
              Contact Support
            </Link>
          </div>
        ) : (
          <div className="flex flex-col items-center space-y-4">
            <CheckCircleIcon className="h-16 w-16 text-green-500 dark:text-green-400" />
            <h1 className="text-3xl font-bold">Email Confirmed!</h1>
            <p className="text-gray-500 dark:text-gray-400">
              Your email has been successfully confirmed. You can now buy
              something.
            </p>
            <Link
              to={"/"}
              className="inline-flex items-center justify-center h-10 px-5 text-white bg-primary-100 rounded-md"
            >
              Go to Home
            </Link>
          </div>
        )}
      </main>
    </div>
  );
};

function CheckCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
      <polyline points="22 4 12 14.01 9 11.01" />
    </svg>
  );
}

function AlertCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" x2="12" y1="8" y2="12" />
      <line x1="12" x2="12.01" y1="16" y2="16" />
    </svg>
  );
}

export default ConfirmEmailPage;
