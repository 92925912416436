import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <main className="flex flex-col items-center justify-center h-[400px] bg-gray-50">
      <div className="flex flex-col items-center space-y-4">
        <AlertCircleIcon className="h-16 w-16 text-red-500 dark:text-red-400" />
        <h1 className="text-3xl font-bold">404</h1>
        <p className="text-gray-500 dark:text-gray-400">
          Oops! The page you're looking for cannot be found. Please check the
          URL or go back to the homepage.
        </p>
        <Link
          to={"/"}
          className="inline-flex items-center justify-center h-10 px-5 text-white bg-red-600 rounded-md hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-400"
        >
          Go to Homepage
        </Link>
      </div>
    </main>
  );
};

export default ErrorPage;

function AlertCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" x2="12" y1="8" y2="12" />
      <line x1="12" x2="12.01" y1="16" y2="16" />
    </svg>
  );
}
