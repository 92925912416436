import React from "react";
import MyInfoForm from "../components/form/MyInfoForm";
import MyPwdForm from "../components/form/MyPwdForm";

const EditProfilePage = () => {
  return (
    <div>
      <h1 className="text-2xl font-medium capitalize mb-4">
        Information Generale
      </h1>
      <MyInfoForm />
    </div>
  );
};

export default EditProfilePage;
