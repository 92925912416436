import React, { useRef } from "react";
import CircleButton from "../ui/CircleButton";
import { Icons } from "../../constants";
import SwiperCustom from "../ui/SwiperCustom";

const CardSection = ({
  subTitle,
  title,
  color,
  bgColor,
  data,
  card,
  type = 1,
  bottomPos = true,
  slides = 4,
  hide = false,
  onClick = null,
  cssContainer = "my-20",
}) => {
  const swiperRef = useRef(null);

  const handleSwiperNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const handleSwiperPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className={`${cssContainer}`}>
      <div className="flex items-center"></div>
      <div className="mt-0">
        {subTitle && (
          <h3
            className={`w-fit mb-2 capitalize font-semibold text-xs md:text-sm h-fit mt-[5px] bg-gray-100 rounded-tr-full rounded-br-full px-2 py-[3px] ${color}`}
          >
            {subTitle}
          </h3>
        )}
        <div className="flex items-start justify-between">
          <div className="flex justify-between space-x-4">
            <h1 className="font-semibold font-inter text-lg md:text-3xl mb-2">
              {title}
            </h1>
          </div>
          {bottomPos ? (
            <div className={`items-center space-x-4 flex`}>
              <CircleButton
                icon={Icons.ArrowLeft}
                name={"icon arrow left"}
                bg={"bg-gray-100"}
                onClick={handleSwiperPrev}
              />
              <CircleButton
                icon={Icons.ArrowRight}
                name={"icon arrow right"}
                bg={"bg-gray-100"}
                onClick={handleSwiperNext}
              />
            </div>
          ) : (
            <button
              className={`capitalize rounded-sm text-xs md:text-base text-white py-2 px-4 md:px-12 h-fit font-medium ${bgColor}`}
            >
              voir tous
            </button>
          )}
        </div>
        <SwiperCustom
          ref={swiperRef}
          data={data}
          card={card}
          type={type}
          slides={slides}
        />
      </div>

      {!hide && bottomPos && (
        <div className="mt-8 flex justify-center">
          <button
            onClick={onClick}
            className={`first-letter:capitalize text-xs md:text-base rounded-xl border border-primary-100 py-2 md:py-4 px-8 md:px-16 font-medium ${color} hover:${bgColor} hover:text-white hover:bg-primary-100`}
          >
            voir tous les produits
          </button>
        </div>
      )}
    </div>
  );
};

export default CardSection;
