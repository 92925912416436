import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const send = (payload) => {
  return axios
    .post(`${url}/partenaires`, payload)
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  send,
};
