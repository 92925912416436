import React, { useEffect, useState } from "react";
import CommentForm from "../components/form/CommentForm";
import Ratings from "../components/ui/Ratings";
import { useDispatch, useSelector } from "react-redux";
import { commentActions } from "../store/product/comment-slice";
import { useQuery } from "react-query";
import commentService from "../services/commentService";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

const CommentBox = ({ itemId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const path = window.location.pathname;

  const comments = useSelector((state) => state.comment.item);

  useEffect(() => {
    dispatch(commentActions.clearData());
  }, []);

  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "comments",
    () => commentService.getAllComments(id, path),
    {
      onSuccess: (data) => {
        dispatch(commentActions.replaceData(data));
      },
    }
  );

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1 className="capitalize font-semibold font-inter text-lg md:text-3xl mb-6">
        evaluer cet article
      </h1>
      {/* <div className="flex space-x-4">
        <div className="rounded-full flex items-center justify-center font-semibold w-14 h-14 bg-secondary-100 text-white text-2xl">
          M
        </div>
      </div> */}
      <CommentForm itemId={itemId} />
      <div className="space-y-6 mt-10">
        <h1 className="capitalize font-semibold font-inter text-lg md:text-3xl mb-6">
          tous les commentaires ({comments ? comments.length : 0})
        </h1>
        {isLoading && comments.length > 0
          ? [1, 2, 3].map((idx) => (
              <div
                key={idx}
                className={`${idx < 4 - 1 && "border-b border-gray-300 pb-4"}`}
              >
                <div className="flex space-x-4 w-full">
                  <div className="rounded-full flex justify-center font-semibold text-white capitalize">
                    <SkeletonCircle width={14} height={14} />
                  </div>
                  <div className="!w-full mb-6">
                    <div className="flex items-center space-x-4 mb-4">
                      <Skeleton height={3} width={100} />
                      <Skeleton height={3} width={100} />
                    </div>
                    <SkeletonText height={30} width={"100%"} />
                  </div>
                </div>
              </div>
            ))
          : comments.map((item, idx) => (
              <div
                key={item.id}
                className={`${
                  idx < comments.length - 1 && "border-b border-gray-300 pb-4"
                }`}
              >
                <CommentCard
                  name={item.user.name}
                  date={item.createdAt}
                  comment={item.content}
                  rate={item.rate}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default CommentBox;

const CommentCard = ({ name, date, comment, rate }) => {
  const convertDateFormat = (date) => {
    const d = new Date(date);
    const formattedDate = d
      .toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");

    return formattedDate;
  };

  return (
    <div className="flex space-x-4">
      <div className="rounded-full flex items-center justify-center font-semibold w-14 h-14 text-white capitalize">
        <p className="w-14 h-14 rounded-full bg-secondary-100 flex items-center justify-center text-2xl">
          {name.substring(0, 1)}
        </p>
      </div>
      <div>
        <div className="flex items-center space-x-4 mb-1">
          <h3 className="font-medium capitalize">{name}</h3>
          <p className="text-sm text-gray-500">{convertDateFormat(date)}</p>
        </div>
        <Ratings nbr={rate} />
        <p className="font-light mt-4">{comment}</p>
      </div>
    </div>
  );
};
