import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import commentService from "../../services/commentService";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Spinner } from "@chakra-ui/react";
import { commentActions } from "../../store/product/comment-slice";
import Ratings from "../ui/Ratings";

const CommentForm = ({ itemId }) => {
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(0);

  const toast = useToast();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  // add new data
  const { register, handleSubmit, reset, setValue, getValues } = useForm({});

  const onSubmit = (data) => {
    setLoading(true);
    commentService
      .add(user.token, { ...data, ...itemId, rate: rate })
      .then((res) => {
        toast({
          title: "Comment ajoute.",
          description: "Votre message a ete bien envoyer.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        dispatch(commentActions.addData(res));
        setLoading(false);
        reset();
        setRate(0);
      })
      .catch((err) => {
        toast({
          title: "Error.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="ajouter un commentaire"
        {...register("content")}
        className="border rounded-lg p-4 border-gray-400 w-full h-[200px] outline-none"
      ></textarea>
      <div className="flex justify-end space-x-4">
        <Ratings edit setRate={setRate} rate={rate} />
        <button
          type="submit"
          className="bg-primary-100 text-white capitalize px-8 py-3 rounded-lg font-semibold text-sm"
        >
          {loading ? (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="md"
            />
          ) : (
            "envoi"
          )}
        </button>
      </div>
    </form>
  );
};

export default CommentForm;
