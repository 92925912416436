import React from "react";
import { Images, Icons, Logos } from "../constants";
import SocialMedia from "../components/ui/SocialMedia";
import InputCustom from "../components/ui/InputCustom";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-primary-100 text-[#FAFAFA] mt-10">
      <div className="py-10 responsive">
        <div className="flex items-center justify-center flex-col ">
          <Link to={"/"} className="col-span-3 flex items-center space-x-2">
            <img
              src={Logos.Logo2}
              alt="logo algerie telecom"
              // className="w-32 mt-1"
              className="w-10 mt-1"
            />
            <img
              src={Logos.Logo}
              alt="logo algerie telecom"
              // className="w-28 mt-1"
              className="w-20 mt-1"
            />
          </Link>
          <a
            href="https://www.algerietelecom.dz/"
            target="_blank"
            className="text-xs mt-2 hover:text-secondary-100"
          >
            Algerie Telecom
          </a>
        </div>
        <div className="mt-8">
          <ul className="grid gap-4 md:flex items-center space-x-4 md:space-x-0 mx-auto w-fit font-medium">
            <li className="textStyle hover:!text-secondary-100 text-center">
              <Link to={"/conditions"}>conditions</Link>
            </li>
            <li className="textStyle hover:!text-secondary-100 text-center">
              <Link to={"/sales"}>service après vente</Link>
            </li>
            <li className="textStyle hover:!text-secondary-100 text-center">
              <Link to={"/devenir-partenaire"}>devenir partenaire</Link>
            </li>
            <li className="textStyle hover:!text-secondary-100 text-center">
              <a href={"https://store.at.dz/1-en/index.html"} target="_blank">
                store virtuel
              </a>
            </li>
          </ul>
        </div>
        <div className="my-6">
          <ul className="space-x-3 flex items-center w-fit mx-auto">
            <li className="flex items-center justify-center border rounded-full w-8 h-8">
              <img src={Icons.Facebook} alt="" className="w-4" />
            </li>
            <li className="flex items-center justify-center border rounded-full w-8 h-8">
              <img src={Icons.Twitter} alt="" className="w-4" />
            </li>
            <li className="flex items-center justify-center border rounded-full w-8 h-8">
              <img src={Icons.Linkedin} alt="" className="w-4" />
            </li>
          </ul>
        </div>
        <p className="text-sm text-center w-2/3 mx-auto text-gray-300">
          Nous nous positionnons en tant que leader dans l'approvisionnement de
          routeurs répondant à tous les besoins en télécommunication. Notre
          engagement consiste à fournir des routeurs de haute qualité et à
          offrir un service exceptionnel à nos clients
        </p>
      </div>
    </footer>
  );
};

export default Footer;
