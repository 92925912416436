import React, { useEffect, useState } from "react";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import CartTable from "../components/ui/CartTable";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Radio,
  useDisclosure,
  RadioGroup,
} from "@chakra-ui/react";
import basketService from "../services/basketService";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Icons } from "../constants";
import { Link, useNavigate } from "react-router-dom";

const CartPage = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const [accept, isAccept] = useState(false);

  // get products
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "my-basket",
    () => {
      if (user) return basketService.get(user.token);
      else return [];
    }
  );

  useEffect(() => {
    refetch();
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const handleRemoveFromCart = (id) => {
    setLoading(true);
    basketService
      .removeItem(id, user.token)
      .then((res) => {
        console.log(res);
        setLoading(false);
        refetch();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const calcTotal = (data) => {
    const totalEachOne = data.map((item) => {
      const amountValue = item.amount ?? 1;
      const discount = item.product
        ? item.product.discount
        : item.course.discount;
      const price = item.product ? item.product.price : item.course.price;

      return (1 - discount / 100) * price * amountValue;
    });

    return totalEachOne.reduce((acc, item) => item + acc, 0).toFixed(2);
  };

  return (
    <div className="">
      <main className="responsive my-10">
        <h1 className="text-4xl capitalize font-bold mb-8">My cart</h1>
        <div className="grid grid-cols-3 gap-20">
          <div className="col-span-2 space-y-6">
            <CartTable
              data={data}
              handleRemove={handleRemoveFromCart}
              loading={loading}
            />
          </div>
          <Card borderRadius={"xl"} className="overflow-hidden mt-10 h-fit">
            <CardBody className="bg-gray-100 !h-fit">
              <div className="">
                <h2 className="text-xl font-semibold capitalize mb-4">
                  methode de payment
                </h2>
                <RadioGroup
                  className="flex flex-col space-y-2"
                  colorScheme="green"
                  value="1"
                >
                  <Radio value="1" borderColor={"gray"}>
                    Cib edhabia
                  </Radio>
                </RadioGroup>
              </div>
              <div className="mt-10 space-y-3 text-sm">
                <div className="flex items-center justify-between capitalize">
                  <p className="">subtotal produits</p>
                  <p className="text-sm">{calcTotal(data.products)} DZD</p>
                </div>
                <div className="flex items-center justify-between capitalize">
                  <p className="">subtotal courses</p>
                  <p className="text-sm">{calcTotal(data.courses)} DZD</p>
                </div>
                <div className="flex items-center justify-between border-t border-gray-300 pt-2 mt-2">
                  <p>total</p>
                  <p className="text-sm">
                    {calcTotal([...data.courses, ...data.products])}
                    DZD
                  </p>
                </div>
                <div className="flex items-start space-x-4 pt-6">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    className="cursor-pointer mt-1"
                    onChange={(e) => isAccept(e.target.checked)}
                  />
                  <p className="text-sm">
                    J'accepte les{" "}
                    <Link
                      to={"/conditions"}
                      className={`text-secondary-100 font-medium hover:underline`}
                    >
                      Conditions générales d'utilisations
                    </Link>
                  </p>
                </div>
                <button
                  disabled={!accept && false}
                  onClick={() => navigate("/payment")}
                  className={`${
                    accept && false ? "bg-secondary-100" : "bg-gray-300"
                  } text-white w-full text-sm p-2 rounded-lg mt-10 flex justify-center items-center space-x-2`}
                >
                  <p>payer (comming soon)</p>
                  <img src={Icons.Pay} alt="" className="w-6" />
                </button>
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default CartPage;
