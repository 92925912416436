import React from "react";
import MyInfoForm from "../components/form/MyInfoForm";
import MyPwdForm from "../components/form/MyPwdForm";
import orderService from "../services/orderService";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../constants";

const PurchasePage = () => {
  const user = useSelector((state) => state.auth.user);

  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "my-orders",
    () => orderService.getAllOrders(user.token)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const convertDateFormat = (date) => {
    const d = new Date(date);
    const formattedDate = d
      .toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");

    return formattedDate;
  };

  const calcTotal = (data) => {
    const totalEachOne = data.map((item) => {
      if (item.product)
        return (
          (1 - item.product.discount / 100) * item.product.price * item.amount
        );
      else if (item.course)
        return (
          (1 - item.course.discount / 100) * item.course.price * item.amount
        );
      return 0;
    });

    return totalEachOne.reduce((acc, item) => item + acc, 0);
  };

  return (
    <div>
      <h1 className="text-2xl font-medium capitalize mb-4">All Orders</h1>
      <div>
        <ul className="grid grid-cols-8 border-b pb-2 mb-4 text-sm font-medium capitalize text-gray-500">
          <li>order</li>
          <li className="col-span-2">total</li>
          <li className="col-span-2">date</li>
          <li className="col-span-2">status</li>
          <li>...</li>
        </ul>
        {data.map((item, idx) => (
          <div key={item.id} className="">
            <ul className="grid grid-cols-8 border-b pb-4 mb-4">
              <li>#{idx + 1}</li>
              <li className="col-span-2">{calcTotal(item.commands)} DZD</li>
              <li className="col-span-2">
                {convertDateFormat(item.createdAt)}
              </li>
              <li
                className={`col-span-2 rounded-full bg-red-50 px-2 w-fit text-white text-sm font-medium ${item.status.toLowerCase()} flex items-center justify-center py-1`}
              >
                {item.status}
              </li>
              <li className="">
                {item.status === "PENDING" && (
                  <button className="bg-gray-400 w-8 h-8 rounded-full flex items-center justify-center">
                    <img src={Icons.Pay} alt="" className="w-5" />
                  </button>
                )}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PurchasePage;
