import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const getAllCategoriesMenu = () => {
  return axios
    .get(`${url}/categories-menu`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const getAllCategoriesParent = () => {
  return axios
    .get(`${url}/categories-parent`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  getAllCategoriesMenu,
  getAllCategoriesParent,
};
