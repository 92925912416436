import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const add = (token, payload) => {
  return axios
    .post(`${url}/comments`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const getAllComments = (id, path) => {
  if (path.includes("products"))
    return axios
      .get(`${url}/product/${id}/comments`)
      .then((response) => response.data)
      .catch((error) => json(error));
  if (path.includes("courses"))
    return axios
      .get(`${url}/course/${id}/comments`)
      .then((response) => response.data)
      .catch((error) => json(error));
};

export default {
  add,
  getAllComments,
};
