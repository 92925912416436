import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Logos, Icons } from "../constants/index";
import CircleButton from "../components/ui/CircleButton";
import SearchForm from "../components/form/SearchForm";
import TopHeader from "./TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth/auth-slice";
import authService from "../services/authService";
import { useQuery } from "react-query";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const itemsCart = useSelector((slice) => slice.cart.item);

  // const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
  //   "email-verify",
  //   () => {
  //     if (user) return authService.isVerify(user.token);
  //     else return [];
  //   }
  // );

  // useEffect(() => {
  //   refetch();
  // }, [user]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) dispatch(authActions.login(JSON.parse(user)));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClass = isScrolled ? "fixed-navbar" : "";

  const navigate = useNavigate();

  return (
    <>
      <div className={`${navbarClass} border-b`}>
        {/* <TopHeader /> */}
        <div className="grid grid-cols-12 responsive py-4">
          <Link to={"/"} className="col-span-3 flex items-center space-x-2">
            <img
              src={Logos.Logo2}
              alt="logo algerie telecom"
              // className="w-32 mt-1"
              className="w-10 mt-1"
            />
            <img
              src={Logos.Logo}
              alt="logo algerie telecom"
              // className="w-28 mt-1"
              className="w-20 mt-1"
            />
          </Link>
          <div className="col-span-9 md:grid grid-cols-12 hidden">
            <div className="col-span-6 hidden md:flex justify-end items-center">
              <ul className="flex justify-between w-full items-center mr-8 space-x-6">
                <li className="capitalize cursor-pointer navLinkStyle">
                  <NavLink to="/">accueil</NavLink>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <NavLink to="/about-us">À propos</NavLink>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <NavLink to="/courses">e-learning</NavLink>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <NavLink to="/promotions">promotions</NavLink>
                </li>
                {/* <li className="capitalize cursor-pointer navLinkStyle">
                  devenir partenaire
                </li> */}
                {/* <li className="capitalize cursor-pointer navLinkStyle">
                  {!user && <NavLink to="/sign-up">sign up</NavLink>}
                </li> */}
              </ul>
            </div>
            <div className="col-span-6 flex items-center justify-between">
              <SearchForm />
              <CircleButton
                icon={Icons.Love}
                name={""}
                onClick={() => navigate("/profile/favorites")}
              />
              <div className="relative">
                <CircleButton
                  icon={Icons.Cart}
                  name={""}
                  onClick={() => navigate("/cart")}
                />
                {itemsCart && (
                  <p className="absolute right-0 top-0 bg-third-100 text-white text-[8px] rounded-full font-medium w-3 h-3 flex items-center justify-center">
                    {itemsCart.length > 9 ? "+9" : itemsCart.length}
                  </p>
                )}
              </div>
              {user ? (
                <img
                  src={Icons.Profile}
                  alt=""
                  className="w-8 cursor-pointer"
                  onClick={() => navigate("/profile")}
                />
              ) : (
                // <CircleButton
                //   icon={Icons.Profile}
                //   name={""}
                //   onClick={() => navigate("/profile")}
                // />
                <button
                  className="bg-primary-100 rounded-md py-3 px-2 text-xs text-white flex-nowrap"
                  onClick={() => navigate("/sign-up")}
                >
                  s'inscrire
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
