import React, { useState } from "react";
import { Icons } from "../../constants";

const Counter = ({ qnt }) => {
  const [number, setNumber] = useState(qnt);

  const increment = () => {
    setNumber((nbr) => nbr + 1);
  };
  const decrement = () => {
    if (number > 1) setNumber((nbr) => nbr - 1);
  };

  return (
    <div className="flex items-center space-x-2">
      <img
        src={Icons.Minus}
        alt="icon minus"
        onClick={decrement}
        className="w-5 cursor-pointer"
      />
      <p className="w-10 text-center bg-secondary-100 rounded text-white py-1 px-2">
        {number}
      </p>
      <img
        src={Icons.Plus}
        alt="icon add"
        onClick={increment}
        className="w-5 cursor-pointer"
      />
    </div>
  );
};

export default Counter;
