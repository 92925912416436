import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const filterParams = (filters) => {
  return Object.entries(filters)
    .filter(([key, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

const getAll = (filters) => {
  return (
    axios
      .get(`${url}/courses?${filterParams(filters)}`, filters)
      // .get(`${url}/courses`, { params: filters })
      .then((response) => response.data)
      .catch((error) => json(error))
  );
};

const getNews = () => {
  return axios
    .get(`${url}/courses/new`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const get = (id) => {
  return axios
    .get(`${url}/courses/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const getSimilar = (id, idCourse) => {
  return axios
    .get(`${url}/courses/${id}/similar`, { idCourse: idCourse })
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  getAll,
  get,
  getSimilar,
  getNews,
};
