import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth/auth-slice";

const ProfilePage = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(authActions.logout());
    navigate("/");
  };

  return (
    <div className="responsive grid grid-cols-3 gap-10 my-10">
      <Card borderRadius={"xl"} className="overflow-hidden h-fit">
        <CardBody className="bg-gray-100 flex flex-col items-center">
          <div className="flex items-center justify-center flex-col mb-10">
            <div className="text-5xl w-20 h-20 rounded-full flex items-center justify-center bg-primary-100 text-white capitalize">
              {user.name && user.name.substring(0, 1)}
            </div>
            <h2 className="text-xl font-semibold capitalize mb-4 text-center mt-2">
              {user.name}
            </h2>
            <p className="text-sm text-gray-400">{user.email}</p>
            <p className="text-sm text-gray-400">{user.phone}</p>
          </div>

          <div className="w-full space-y-2">
            <div>
              <Link to={"./edit-profile"}>
                <p className="bg-gray-200 w-full rounded-lg py-2 text-center first-letter:capitalize">
                  Editer le profil
                </p>
              </Link>
            </div>
            <div>
              <Link to={"./edit-password"}>
                <p className="bg-gray-200 w-full rounded-lg py-2 text-center first-letter:capitalize">
                  modifier le mot de passe
                </p>
              </Link>
            </div>
            <div>
              <Link to={"./history"}>
                <p className="bg-gray-200 w-full rounded-lg py-2 text-center first-letter:capitalize">
                  historique d'achat
                </p>
              </Link>
            </div>
            <div>
              <Link to={"./favorites"}>
                <p className="bg-gray-200 w-full rounded-lg py-2 text-center capitalize">
                  liste de favoris
                </p>
              </Link>
            </div>
            <div>
              <div>
                <p
                  onClick={handleLogout}
                  className="cursor-pointer text-white bg-third-100 w-full rounded-lg py-2 text-center first-letter:capitalize"
                >
                  Se déconnecter
                </p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="col-span-2 border rounded-xl p-8">
        <Outlet />
      </div>
    </div>
  );
};

export default ProfilePage;
