import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MyPwdForm from "../components/form/MyPwdForm";

const EditPwdPage = () => {
  return (
    <div>
      <h1 className="text-2xl font-medium capitalize mb-4">
        changer le mot de passe
      </h1>
      <MyPwdForm />
    </div>
  );
};

export default EditPwdPage;
