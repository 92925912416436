import React from "react";
import { Images } from "../../constants";

const BannerCard = ({ item }) => {
  return (
    <a href={item.url} className="cursor-pointer" target="_blank">
      <div className="h-[338px] w-full bg-green-700">
        <img
          src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.image}`}
          // src={Images[item.img]}
          alt={item.images}
          className="w-full h-full object-cover"
        />
      </div>
    </a>
  );
};

export default BannerCard;
