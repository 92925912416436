import Love from "../assets/icons/heart small.svg";
import Cart from "../assets/icons/Cart1.svg";
import Eye from "../assets/icons/Group (2).svg";
import Search from "../assets/icons/Component 2.svg";
import Instagram from "../assets/icons/Group (1).svg";
import Facebook from "../assets/icons/Icon-Facebook.svg";
import Linkedin from "../assets/icons/Icon-Linkedin.svg";
import Twitter from "../assets/icons/Group.svg";
import Send from "../assets/icons/icon-send.svg";
import ArrowRight from "../assets/icons/icons arrow-right.svg";
import ArrowLeft from "../assets/icons/icons_arrow-left.svg";
import ArrowRight2 from "../assets/icons/rightArrow.svg";
import StarActive from "../assets/icons/star-active.svg";
import Star from "../assets/icons/star.svg";
import DropDown from "../assets/icons/DropDown.svg";
import Camera from "../assets/icons/Category-Camera.svg";
import Phone from "../assets/icons/Category-CellPhone.svg";
import Computer from "../assets/icons/Category-Computer.svg";
import Gamepad from "../assets/icons/Category-Gamepad.svg";
import Headphone from "../assets/icons/Category-Headphone.svg";
import Watch from "../assets/icons/Category-Watch.svg";
import CameraWhite from "../assets/icons/Category-Camera-white.svg";
import PhoneWhite from "../assets/icons/Category-CellPhone-white.svg";
import ComputerWhite from "../assets/icons/Category-Computer-white.svg";
import GamepadWhite from "../assets/icons/Category-Gamepad-white.svg";
import HeadphoneWhite from "../assets/icons/Category-Headphone-white.svg";
import WatchWhite from "../assets/icons/Category-SmartWatch-white.svg";
import Google from "../assets/icons/Icon-Google.svg";
import Plus from "../assets/icons/Plus.svg";
import Minus from "../assets/icons/Minus.svg";
import Close from "../assets/icons/Close.svg";
import CartWhite from "../assets/icons/cart-white.svg";
import Info from "../assets/icons/info.svg";
import Manuel from "../assets/icons/manuel.svg";
import Pay from "../assets/icons/pay.svg";
import Profile from "../assets/icons/profile.svg";

export default {
  Manuel,
  Profile,
  CartWhite,
  Info,
  Pay,
  Love,
  Cart,
  Instagram,
  Eye,
  Search,
  Facebook,
  Linkedin,
  Twitter,
  Send,
  ArrowLeft,
  ArrowRight,
  ArrowRight2,
  StarActive,
  Star,
  DropDown,
  Camera,
  Phone,
  Computer,
  Gamepad,
  Headphone,
  Watch,
  CameraWhite,
  PhoneWhite,
  ComputerWhite,
  GamepadWhite,
  HeadphoneWhite,
  WatchWhite,
  Google,
  Plus,
  Minus,
  Close,
};
