import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const filterParams = (filters) => {
  return Object.entries(filters)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

const get = (token) => {
  return axios
    .get(`${url}/my-basket`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const addItem = (token, payload) => {
  return axios
    .post(`${url}/my-basket`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const removeItem = (id, token) => {
  return axios
    .delete(`${url}/my-basket/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  get,
  addItem,
  removeItem,
};
