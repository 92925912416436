import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import basketService from "../../services/basketService";
import { cartActions } from "../../store/cart/cart-slice";
import { useQuery } from "react-query";
import { Icons } from "../../constants";

const CartDrawer = ({ isOpen, onClose, onOpen }) => {
  const navigate = useNavigate();

  const [total, setTotal] = useState(0);

  const itemsCart = useSelector((slice) => slice.cart.item);
  // open cart
  // useEffect(() => {
  //   onOpen();
  // }, [itemsCart]);

  const handleGoCart = () => {
    navigate("/cart");
  };

  const calcTotal = () => {
    if (itemsCart) {
      const sum = itemsCart.reduce((acc, item) => {
        const amount = item.amount ?? 1;
        const discount = item.product
          ? item.product.discount
          : item.course
          ? item.course.discount
          : 0;
        const price = item.product
          ? item.product.price
          : item.course
          ? item.course.price
          : 0;

        // const res = (1 - discount / 100) * price * amount + acc;
        const res = (1 - discount / 100) * price * amount + parseFloat(acc);
        return parseFloat(res).toFixed(2);
      }, 0);

      setTotal(sum ?? 0);
    }
  };

  useEffect(() => {
    calcTotal();
  }, [itemsCart]);

  return (
    <div>
      {itemsCart && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Votre Paniter</DrawerHeader>

            <DrawerBody className="space-y-3">
              {itemsCart.map((item, idx) => {
                return (
                  <ElementCart
                    key={idx}
                    item={item}
                    isBorder={idx < itemsCart.length - 1}
                  />
                );
              })}
            </DrawerBody>

            <DrawerFooter className="flex flex-col space-y-2 w-full border-t pt-4">
              <h1 className="font-medium flex items-center justify-between w-full">
                <span>Sous-total:</span>
                <span className="text-primary-100">
                  {/* {itemsCart.reduce((acc, item) => {
                    const amount = item.amount ?? 1;
                    const discount = item.product
                      ? item.product.discount
                      : item.course.discount;
                    const price = item.product
                      ? item.product.price
                      : item.course.price;

                    const res = (1 - discount / 100) * price * amount + acc;
                    return parseFloat(res).toFixed(2);
                  }, 0)} */}
                  {total} DZD
                </span>
              </h1>
              <button
                className="bg-secondary-100 text-white capitalize py-2 font-medium w-full rounded-lg text-sm"
                onClick={handleGoCart}
              >
                <div className="flex items-center space-x-2 justify-center">
                  <p>voir le panier</p>
                  <img src={Icons.CartWhite} alt="" className="w-6" />
                </div>
              </button>
              <button className="bg-primary-100 text-white capitalize py-2 font-medium w-full rounded-lg text-sm">
                <div className="flex items-center space-x-2 justify-center">
                  <p>payer</p>
                  <img src={Icons.Pay} alt="" className="w-6" />
                </div>
              </button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </div>
  );
};

export default CartDrawer;

const ElementCart = ({ item, isBorder }) => {
  const newPriceProduct =
    item.product &&
    ((1 - item.product.discount / 100) * item.product.price).toFixed(2);
  const newPriceCourse =
    item.course &&
    ((1 - item.course.discount / 100) * item.course.price).toFixed(2);
  return (
    <div
      key={item.id}
      className={`flex items-center space-x-4 ${
        isBorder && "border-b border-gray-400 pb-3"
      }`}
    >
      {item.product && item.product.images.length && (
        <div
          className={` shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] border border-gray-300 rounded-lg w-20 h-20 overflow-hidden`}
        >
          <img
            src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${
              item.product.images[item.product.mainImage]
            }`}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
      )}
      {item.course && item.course.images.length > 0 && (
        <div className={`border rounded-lg w-20 h-20 overflow-hidden`}>
          <img
            src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${
              item.course.images[item.course.mainImage]
            }`}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
      )}
      <div>
        <h3 className="text-sm font-medium mb-2">
          {(item.product && item.product.name) ||
            (item.course && item.course.name)}
        </h3>
        <div className="flex items-center space-x-3">
          <p className="text-gray-400 text-xs">{item.amount ?? 1} x</p>
          <p className="font-medium text-primary-100 text-xs">
            {newPriceCourse || newPriceProduct} DZD
          </p>
        </div>
      </div>
    </div>
  );
};
