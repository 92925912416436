import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import productData from "../../data/products.json";
import Counter from "./Counter";
import { Icons, Images } from "../../constants";
import { useQuery } from "react-query";
import basketService from "../../services/basketService";
import { Spinner } from "@chakra-ui/react";

const CartTable = ({ data, handleRemove, loading }) => {
  const [selected, setSelected] = useState(null);

  return (
    <div className="space-y-10 bg-white rounded-lg p-4">
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>product</Th>
              <Th>price</Th>
              <Th>quantity</Th>
              <Th>total</Th>
              <Th>...</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.products.map((item) => {
              const discount = (
                ((100 - item.product.discount) / 100) *
                item.product.price
              ).toFixed(2);
              return (
                <Tr key={item.id}>
                  <Td>
                    <Card item={item.product} />
                  </Td>
                  <Td>
                    <p
                      className={`${
                        item.product.discount > 0 && "line-through"
                      }`}
                    >
                      {item.product.price} DZD
                    </p>
                    {item.product.discount > 0 && <p>{discount} DZD</p>}
                  </Td>
                  <Td>
                    <Counter qnt={item.amount} />
                  </Td>
                  <Td>{(discount * item.amount).toFixed(2)} DZD</Td>
                  <Td>
                    {selected === item.id && loading ? (
                      <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                      />
                    ) : (
                      <div
                        className="cursor-pointer border border-primary-100 w-6 h-6 rounded-full"
                        onClick={() => {
                          setSelected(item.id);
                          handleRemove(item.id);
                        }}
                      >
                        <img
                          src={Icons.Close}
                          alt="icon remove"
                          className="w-6 cursor-pointer"
                        />
                      </div>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>course</Th>
              <Th>price</Th>
              <Th>...</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.courses.map((item) => {
              const discount = (
                ((100 - item.course.discount) / 100) *
                item.course.price
              ).toFixed(2);
              return (
                <Tr key={item.id}>
                  <Td>
                    <Card item={item.course} />
                  </Td>
                  <Td>
                    <p
                      className={`${
                        item.course.discount > 0 && "line-through"
                      }`}
                    >
                      {item.course.price} DZD
                    </p>
                    {item.course.discount > 0 && <p>{discount} DZD</p>}
                  </Td>
                  <Td>
                    {selected === item.id && loading ? (
                      <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                      />
                    ) : (
                      <div
                        className="cursor-pointer w-fit"
                        onClick={() => handleRemove(item.id)}
                      >
                        <img
                          src={Icons.Close}
                          alt="icon remove"
                          className="w-6 cursor-pointer"
                        />
                      </div>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CartTable;

const Card = ({ item }) => {
  return (
    <div className="flex space-x-4">
      <div className="border rounded-lg overflow-hidden h-32 w-32">
        {item.images && (
          <img
            src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${
              item.images[item.mainImage]
            }`}
            alt=""
            className="w-full h-full object-cover"
          />
        )}
      </div>
      <div className="pt-2">
        <h3 className="font-semibold text-xl">{item.name}</h3>
        {/* <p className="capitalize">{item.category.name}</p> */}
      </div>
    </div>
  );
};
