import React, { useState } from "react";
import { Select } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import partenaireService from "../../services/partenaireService";
import { useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import dataWilaya from "../../data/Wilaya_Of_Algeria.json";
import dataCommune from "../../data/Commune_Of_Algeria.json";

const PaymentForm = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // add new data
  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm(
    {}
  );

  const onSubmit = (data) => {
    setLoading(true);
    partenaireService
      .send(data)
      .then((res) => {
        toast({
          title: "Devenir partenaire.",
          description: "Votre message a ete bien envoyer.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        reset();
      })
      .catch((err) => {
        toast({
          title: "Error.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  const formValues = watch();

  return (
    <form className="grid grid-cols-4 gap-3" onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register("raison", { required: true })}
        type="text"
        placeholder="nom *"
        className="InputCustom col-span-2"
      />
      <input
        {...register("raison", { required: true })}
        type="text"
        placeholder="prenom *"
        className="InputCustom col-span-2"
      />
      <input
        {...register("phone", { required: true })}
        type="tel"
        placeholder="Numero de telephone *"
        className="InputCustom col-span-2"
      />
      <input
        {...register("email", { required: true })}
        type="email"
        placeholder="Email"
        className="InputCustom col-span-2"
      />
      <Select
        placeholder="Wilaya *"
        size={"md"}
        {...register("wilaya", { required: true })}
      >
        {dataWilaya.map((item) => (
          <option key={item.id} value={item.code}>
            {item.code} - {item.name}
          </option>
        ))}
      </Select>
      <Select
        placeholder="Commune *"
        size={"md"}
        {...register("Commune", { required: true })}
      >
        {formValues.wilaya &&
          dataCommune
            .filter((item) => item.wilaya_id === formValues.wilaya)
            .map((item) => (
              <option key={item.id} value={item.code}>
                {item.code} - {item.name}
              </option>
            ))}
      </Select>
      <input
        {...register("email", { required: true })}
        type="text"
        placeholder="longtitude"
        className="InputCustom"
      />
      <input
        {...register("email", { required: true })}
        type="text"
        placeholder="latitude"
        className="InputCustom"
      />
      <button
        type="submit"
        className="col-start-4 mt-10 bg-secondary-100 text-white w-full py-2 font-medium capitalize rounded-lg text-xs md:text-base"
      >
        {loading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          "valider"
        )}
      </button>
    </form>
  );
};

export default PaymentForm;
