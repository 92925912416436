import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import saleService from "../services/saleService";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

const SalesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "sales",
    () => saleService.getAll()
  );

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="responsive my-10 space-y-4">
      {isLoading
        ? [1, 2, 3].map((item) => (
            <Card
              key={item}
              borderRadius={"xl"}
              className="overflow-hidden h-fit"
            >
              <CardBody className="bg-gray-100 grid grid-cols-8 gap-x-8">
                <div className="h-full w-full col-span-2 rounded-lg overflow-hidden border">
                  <Skeleton width={"100%"} height={"100%"} />
                </div>
                <div className="py-4 w-full space-y-3">
                  <Skeleton width={300} height={7} rounded={"md"} />
                  <Skeleton width={300} height={7} rounded={"md"} />
                  <Skeleton width={300} height={7} rounded={"md"} />
                  <Skeleton width={300} height={7} rounded={"md"} />
                  <Skeleton width={300} height={7} rounded={"md"} />
                </div>
              </CardBody>
            </Card>
          ))
        : data.map((item) => (
            <Card borderRadius={"xl"} className="overflow-hidden h-fit">
              <CardBody className="bg-gray-100 grid grid-cols-8 gap-x-8">
                <div className="h-full w-full bg-green-700 col-span-2 rounded-lg overflow-hidden border">
                  <img
                    alt={""}
                    className="w-full h-full object-cover"
                    src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.images[0]}`}
                  />
                </div>
                <div className="col-span-6 grid grid-cols-4 gap-y-4 py-4">
                  <h5 className="font-medium capitalize">fournisseur:</h5>
                  <div className="col-span-3">
                    <p>{item.name}</p>
                  </div>
                  <h5 className="font-medium capitalize">Adresse:</h5>
                  <div className="col-span-3">
                    <p>{item.address}</p>
                  </div>
                  <h5 className="font-medium capitalize">Tel:</h5>
                  <div className="col-span-3">
                    <a href={`tel:+${item.phone}`}>{item.phone}</a>
                  </div>
                  <h5 className="font-medium capitalize">Email:</h5>
                  <div className="col-span-3">
                    <a href={`mailto:${item.email}`}>{item.email}</a>
                  </div>
                  <h5 className="font-medium capitalize">Site web:</h5>
                  <div className="col-span-3">
                    <a
                      className="text-secondary-100 cursor-pointer  "
                      href={item.webiste}
                      target="_blank"
                    >
                      {item.website}
                    </a>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
    </div>
  );
};

export default SalesPage;
