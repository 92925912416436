import React from "react";
import PopoverCustom from "../components/ui/PopoverCustom";
import dataSidebar from "../data/sidebar.json";
import { Link, useNavigate } from "react-router-dom";
import categoryService from "../services/categoryService";
import { useQuery } from "react-query";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

const SideBar = () => {
  const navigate = useNavigate();
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "categories-menu",
    () => categoryService.getAllCategoriesMenu()
  );

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <ul className="space-y-4">
        <li className="flex items-center justify-between">
          <Link to={"/products"} className="first-letter:capitalize">
            tous les produits
          </Link>
        </li>
        {isLoading
          ? [1, 2, 3, 4, 5].map((item) => (
              <li key={item}>
                <Skeleton height={4} borderRadius={10} />
              </li>
            ))
          : data.map((item, idx) => (
              <li
                className="flex items-center justify-between w-full"
                key={idx}
              >
                <PopoverCustom
                  type={item.category.name === "e-learning" && "course"}
                  item={{
                    title: item.category.name,
                    sub: item.subcategories,
                  }}
                />
              </li>
            ))}
      </ul>

      <div className="my-6">
        <button
          className="bg-secondary-100 text-white rounded-xl first-letter:capitalize font-medium px-6 py-2"
          onClick={() => navigate("/devenir-partenaire")}
        >
          devenir partenaire
        </button>
        <button className="bg-primary-100 text-white rounded-xl first-letter:capitalize font-medium px-6 py-2 mt-3">
          guide d'utilisation
        </button>
      </div>
    </div>
  );
};

export default SideBar;
