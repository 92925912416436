import React, { useEffect, useRef, useState } from "react";
import dataProducts from "../data/products.json";
import ProductCard from "../components/ui/ProductCard";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import PathBar from "../layout/PathBar";
import { useLocation, useParams } from "react-router-dom";
import DetailsPopUp from "../components/ui/DetailsPopUp";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../store/product/product-slice";
import CardSection from "../components/section/CardSection";
import { Icons, Images } from "../constants";
import SwiperCustom from "../components/ui/SwiperCustom";
import CommentBox from "../layout/CommentBox";
import productsService from "../services/productsService";
import { useQuery } from "react-query";
import basketService from "../services/basketService";
import { cartActions } from "../store/cart/cart-slice";
import CartDrawer from "../components/ui/CartDrawer";
import { Spinner } from "@chakra-ui/react";
import favoriteService from "../services/favoriteService";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import saleService from "../services/saleService";

const ProductDetailPage = () => {
  const swiperImgs = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [item, setItem] = useState(null);

  const user = useSelector((state) => state.auth.user);

  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  // get products
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "product",
    () => productsService.get(id)
  );

  const handleDetailSale = () => {
    onOpen();
  };

  // get products
  const {
    isLoading: isLoadingSimilair,
    data: dataSimilair,
    isError: isErrorSimilair,
    error: errorSimilair,
    isFetching: isFetchingSimilair,
    refetch: refetchSimilair,
  } = useQuery("product-similair", () => productsService.getSimilar(id));

  const {
    isLoading: isLoadingCart,
    data: dataCart,
    isError: isErrorCart,
    error: errorCart,
    isFetching: isFetchingCart,
    refetch: refetchCart,
  } = useQuery(
    "my-basket",
    () => {
      if (user) return basketService.get(user.token);
      else return [];
    },
    {
      onSuccess: (data) => {
        if (data && data.products && data.courses) {
          const res = [...data.products, ...data.courses];
          dispatch(cartActions.replaceData(res));
        }
      },
    }
  );

  useEffect(() => {
    refetchCart();
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddToCart = () => {
    setLoading(true);
    basketService
      .addItem(user.token, {
        type: "product",
        item: id,
      })
      .then((res) => {
        dispatch(cartActions.addData(res));
        setLoading(false);
        onOpenCart();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleFavorite = () => {
    favoriteService
      .add(user.token, {
        type: "product",
        item: id,
      })
      .then((res) => {
        dispatch(cartActions.addData(res));
        setLoading(false);
        onOpenCart();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="my-10 responsive">
        <PathBar />
      </div>

      <div className="responsive">
        {/* detail product  */}
        <div className="flex flex-col md:grid md:grid-cols-12 gap-x-4 border-b">
          <div className="col-span-7">
            <SwiperCustom
              data={data.images}
              card={({ item }) => {
                return (
                  <div className="h-[550px] w-full bg-green-700">
                    <img
                      alt={item}
                      className="w-full h-full object-cover"
                      src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item}`}
                    />
                  </div>
                );
              }}
              slides={1}
              ref={swiperImgs}
              pagination
              autoplay
            />
          </div>

          {/* start details  */}
          <div className="bg-gray-100 h-full col-span-5 px-4 pt-4 md:pt-8 pb-4">
            {/* start general information  */}
            <h1 className="capitalize text-2xl font-bold">General</h1>
            <div className="mt-2 grid grid-cols-12">
              <div className="h-[95px] border col-span-3 rounded-lg overflow-hidden bg-white">
                <img
                  src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${
                    data.images[data.mainImage]
                  }`}
                  alt=""
                  className="w-full rounded-xl object-cover"
                />
              </div>
              <div className="pl-4 col-span-9 flex flex-col justify-between">
                <div className="flex items-center justify-between">
                  <p className="text-third-100 font-medium text-sm">
                    {data.price} DZD
                  </p>
                  <img src={Icons.Love} alt="love icon" />
                </div>
                <h3 className="font-medium text-xl">{data.name}</h3>
                <div className="flex items-center space-x-1">
                  <img src={Icons.StarActive} alt="icon star" />
                  <p>{data.rate ?? 0}</p>
                  <p className="pl-2 text-gray-400 text-sm">
                    ({data.reviews ?? 0} commentaires)
                  </p>
                </div>
              </div>
            </div>
            {/* start description  */}
            <h4 className="font-medium mt-6 text-gray-500 text-sm">
              Description
            </h4>
            <div className="bg-gray-600 text-gray-100 rounded-lg px-4 py-3 mt-3">
              <h4 className="text-sm">
                <span className="font-bold capitalize">model:</span>{" "}
                {data.model}
              </h4>
              <h4 className="text-sm">
                <span className="font-bold capitalize">marque:</span>{" "}
                {data.marque}
              </h4>
              <p className="text-xs mt-2">{data.description}</p>
            </div>
            {/* start key facts  */}
            <h4 className="font-medium mt-6 text-gray-500 text-sm capitalize">
              faits marquants
            </h4>
            <div className="mt-4 grid md:grid-cols-3 gap-y-2 gap-x-4">
              <div className="rounded-lg px-4 py-2 bg-white flex justify-center flex-col">
                <h3 className="text-gray-400 text-sm">Pays</h3>
                <p className="text-sm">{data.country}</p>
              </div>
              <div className="rounded-lg px-4 py-2 bg-white flex justify-center flex-col">
                <h3 className="text-gray-400 text-sm">Date Lancé</h3>
                <p className="text-sm">{data.launched}</p>
              </div>
              <div className="rounded-lg px-4 py-2 bg-white flex justify-center flex-col">
                <h3 className="text-gray-400 text-sm">Categorie</h3>
                <p className="text-sm">{data.category.name}</p>
              </div>
            </div>

            {/* buttons  */}
            <div className="w-full grid md:grid-cols-2 gap-y-2 gap-x-4 text-sm mt-6">
              <button className="w-full bg-secondary-100 text-white rounded-lg mx-auto capitalize px-4 py-3">
                <div className="flex items-center space-x-2 justify-center">
                  <p>manuel</p>
                  <img src={Icons.Manuel} alt="" className="w-6" />
                </div>
              </button>
              <button
                className="w-full bg-primary-100 text-white rounded-lg mx-auto capitalize px-4 py-3"
                onClick={handleAddToCart}
              >
                {loading ? (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <div className="flex items-center space-x-2 justify-center">
                    <p>Ajouter au panier</p>
                    <img src={Icons.CartWhite} alt="" className="w-6" />
                  </div>
                )}
              </button>
              <button
                className="md:col-span-2 w-full border border-primary-100 text-primary-100 rounded-lg mx-auto capitalize px-4 py-3"
                onClick={handleDetailSale}
              >
                {loading ? (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <div className="flex items-center space-x-2 justify-center">
                    <p>service apres vente</p>
                    {/* <img src={Icons.CartWhite} alt="" className="w-6" /> */}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* caracteristiqeus  */}
        <div className="mt-16">
          <h1 className="font-semibold font-inter text-lg md:text-3xl mb-6">
            Caracteristiques
          </h1>
          <ul className="space-y-2">
            {data.features.map((feature) => (
              <li key={feature.key}>
                <span className="font-medium first-letter:capitalize">
                  {feature.key}:
                </span>{" "}
                <span>{feature.value}</span>
              </li>
            ))}
          </ul>
        </div>
        {/* similar product */}
        {isLoadingSimilair ? (
          <></>
        ) : (
          dataSimilair &&
          dataSimilair.length > 0 && (
            <div className="">
              <CardSection
                subTitle={""}
                title={"Similar Products"}
                color={"text-primary-100"}
                bgColor={"bg-primary-100"}
                data={dataSimilair}
                card={ProductCard}
                cssContainer={"py-10"}
                hide
              />
            </div>
          )
        )}
        {/* comments  */}
        {user && (
          <div className="mt-4 mb-10">
            <CommentBox itemId={{ productId: id }} />
          </div>
        )}
      </div>

      {!isLoadingCart && (
        <CartDrawer
          isOpen={isOpenCart}
          onClose={onCloseCart}
          onOpen={onOpenCart}
        />
      )}

      {/* pop up for sale details  */}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={"5xl"}
      >
        <ModalOverlay />
        <ModalContent css={{ padding: "0", overflow: "hidden" }}>
          <ModalCloseButton />
          <ModalBody css={{ padding: "0", overflow: "hidden" }}>
            <DetailSale id={data.saleId} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProductDetailPage;

const DetailSale = ({ id }) => {
  const {
    isLoading: isLoading,
    data: item,
    isError: isError,
    error: error,
    isFetching: isFetching,
    refetch: refetch,
  } = useQuery("sale-details", () => saleService.get(id));

  if (isLoading)
    return (
      <div className="bg-gray-100 grid grid-cols-8 gap-x-8  p-8">
        <div className="h-full w-full col-span-2 rounded-lg overflow-hidden border">
          <Skeleton width={"100%"} height={"100%"} />
        </div>
        <div className="py-4 w-full space-y-3">
          <Skeleton width={300} height={7} rounded={"md"} />
          <Skeleton width={300} height={7} rounded={"md"} />
          <Skeleton width={300} height={7} rounded={"md"} />
          <Skeleton width={300} height={7} rounded={"md"} />
          <Skeleton width={300} height={7} rounded={"md"} />
        </div>
      </div>
    );
  return (
    <div className="bg-gray-100 grid grid-cols-8 gap-x-8 p-8">
      <div className="h-full w-full bg-green-700 col-span-2 rounded-lg overflow-hidden border">
        <img
          alt={""}
          className="w-full h-full object-cover"
          src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.images[0]}`}
        />
      </div>
      <div className="col-span-6 grid grid-cols-4 gap-y-4 py-4">
        <h5 className="font-medium capitalize">fournisseur:</h5>
        <div className="col-span-3">
          <p>{item.name}</p>
        </div>
        <h5 className="font-medium capitalize">Adresse:</h5>
        <div className="col-span-3">
          <p>{item.address}</p>
        </div>
        <h5 className="font-medium capitalize">Tel:</h5>
        <div className="col-span-3">
          <a href={`tel:+${item.phone}`}>{item.phone}</a>
        </div>
        <h5 className="font-medium capitalize">Email:</h5>
        <div className="col-span-3">
          <a href={`mailto:${item.email}`}>{item.email}</a>
        </div>
        <h5 className="font-medium capitalize">Site web:</h5>
        <div className="col-span-3">
          <a
            className="text-secondary-100 cursor-pointer  "
            href={item.webiste}
            target="_blank"
          >
            {item.website}
          </a>
        </div>
      </div>
    </div>
  );
};
