import React, { useEffect, useRef, useState } from "react";
import { Icons, Images } from "../../constants";
import CardSection from "../section/CardSection";
import ProductCard from "./ProductCard";
import dataProducts from "../../data/products.json";
import SwiperCustom from "./SwiperCustom";
import BannerCard from "./BannerCard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import basketService from "../../services/basketService";
import productService from "../../services/productsService";
import { Spinner } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { cartActions } from "../../store/cart/cart-slice";
import Ratings from "./Ratings";

const ProductPopUp = ({ onClose, onOpen, scrollToTop, clearData }) => {
  const [loading, setLoading] = useState(false);
  const [inCart, isInCart] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const swiperImgs = useRef();
  const select = useSelector((slice) => slice.product.item);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    scrollToTop();
  }, [select]);

  // get products similair
  // const {
  //   isLoading: isLoadingSimilar,
  //   data: dataSimilar,
  //   isError: isErrorSimilar,
  //   error: errorSimilar,
  // } = useQuery("products-similar", () =>
  //   productService.getSimilar(select.categoryId, select.id)
  // );
  // if (isLoadingSimilar) {
  //   return <div>Loading...</div>;
  // }
  // if (isErrorSimilar) {
  //   return <div>Error: {errorSimilar.message}</div>;
  // }

  const handleAddToCart = () => {
    setLoading(true);
    // onOpen();
    basketService
      .addItem(user.token, {
        type: "product",
        item: select.id,
      })
      .then((res) => {
        dispatch(cartActions.addData(res));
        clearData();
        setLoading(false);
        onOpen();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleMoreInfo = (id) => {
    onClose();
    navigate(`/products/${id}`);
  };

  return (
    <div>
      <div className="flex flex-col md:grid md:grid-cols-12 gap-x-4 border-b">
        <div className="col-span-7">
          <SwiperCustom
            data={select.images}
            card={({ item }) => {
              return (
                <div className="h-[550px] w-full bg-green-700">
                  <img
                    src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item}`}
                    alt={select.name}
                    className="w-full h-full object-cover"
                  />
                </div>
              );
            }}
            slides={1}
            ref={swiperImgs}
            pagination
            autoplay
          />
        </div>
        {/* start details  */}

        <div className="bg-gray-100 h-full col-span-5 px-4 pt-4 md:pt-8 pb-4">
          {/* start general information  */}
          <h1 className="capitalize text-2xl font-bold">General</h1>
          <div className="mt-2 grid grid-cols-12">
            <div className="border col-span-3 rounded-lg overflow-hidden bg-white">
              <img
                src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${
                  select.images[select.mainImage]
                }`}
                alt=""
                className="w-full h-[95px] object-cover rounded-xl"
              />
            </div>
            <div className="pl-4 col-span-9 flex flex-col justify-between py-2">
              <div className="flex items-center justify-between">
                <div className="space-x-2 flex items-center">
                  <p
                    className={`${
                      select.discount > 0
                        ? "line-through text-gray-400"
                        : "text-third-100"
                    } font-medium text-sm`}
                  >
                    {select.price} DZD
                  </p>
                  {select.discount > 0 && (
                    <p className="text-third-100 font-medium text-sm">
                      {(1 - select.discount / 100) * select.price} DZD
                    </p>
                  )}
                </div>
                <img src={Icons.Love} alt="love icon" />
              </div>
              <h3 className="font-medium text-xl">{select.name}</h3>
              <div className="flex items-center space-x-1">
                <Ratings nbr={select.rate ?? 0} />
                <p className="pl-2 text-gray-400 text-sm">
                  ({select.reviews ?? 0} commentaires)
                </p>
              </div>
            </div>
          </div>
          {/* start description  */}
          <h4 className="font-medium mt-6 text-gray-500 text-sm">
            Description
          </h4>
          <div className="bg-gray-600 text-gray-100 rounded-lg px-4 py-3 mt-3">
            <h4 className="text-sm">
              <span className="font-bold capitalize">model:</span>{" "}
              {select.model}
            </h4>
            <h4 className="text-sm">
              <span className="font-bold capitalize">marque:</span>{" "}
              {select.marque}
            </h4>
            <p className="text-xs mt-2">{select.description}</p>
          </div>
          {/* start key facts  */}
          <h4 className="font-medium mt-6 text-gray-500 text-sm capitalize">
            key facts
          </h4>
          <div className="mt-4 grid gap-y-2 md:grid-cols-3 gap-x-4">
            <div className="rounded-lg px-4 py-2 bg-white flex justify-center flex-col">
              <h3 className="text-gray-400 text-sm">Country</h3>
              <p className="text-sm">{select.country}</p>
            </div>
            <div className="rounded-lg px-4 py-2 bg-white flex justify-center flex-col">
              <h3 className="text-gray-400 text-sm">Launched</h3>
              <p className="text-sm">{select.launched}</p>
            </div>
            <div className="rounded-lg px-4 py-2 bg-white flex justify-center flex-col">
              <h3 className="text-gray-400 text-sm">Category</h3>
              <p className="text-sm">{select.category.name}</p>
            </div>
          </div>

          {/* buttons  */}
          <div className="w-full grid md:grid-cols-2 gap-x-4 text-sm">
            <button
              onClick={(id) => handleMoreInfo(select.id)}
              className="w-full bg-secondary-100 text-center text-white rounded-lg mt-6 mx-auto capitalize px-4 py-3"
            >
              <div className="flex items-center space-x-2 justify-center">
                <p>more information</p>
                <img src={Icons.Info} alt="" className="w-6" />
              </div>
            </button>
            <button
              disabled={loading}
              onClick={handleAddToCart}
              className="w-full bg-primary-100 text-white rounded-lg mt-2 md:mt-6 mx-auto capitalize py-3"
            >
              {loading ? (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              ) : (
                <div className="flex items-center space-x-2 justify-center">
                  <p>Ajouter au panier</p>
                  <img src={Icons.CartWhite} alt="" className="w-6" />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* similar product */}

      {/* <div className="mx-10">
        <CardSection
          subTitle={""}
          title={"Similar Products"}
          color={"text-primary-100"}
          bgColor={"bg-primary-100"}
          data={dataSimilar}
          card={ProductCard}
          cssContainer={"py-10"}
          hide
        />
      </div> */}
    </div>
  );
};

export default ProductPopUp;
