import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const updateInfo = (token, payload) => {
  return axios
    .patch(`${url}/user/change-info`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const updatePwd = (token, payload) => {
  return axios
    .patch(`${url}/user/change-password`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  updateInfo,
  updatePwd,
};
