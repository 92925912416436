import React, { useState } from "react";
import InputCustom from "../ui/InputCustom";
import { useForm } from "react-hook-form";
import userService from "../../services/userService";
import { authActions } from "../../store/auth/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@chakra-ui/react";

const MyInfoForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: user.name,
      email: user.email,
      phone: user.phone,
    },
  });

  const onSubmit = (data) => {
    if (data.password) {
      setError("");
      setLoading(true);
      userService
        .updateInfo(user.token, data)
        .then((res) => {
          const data = {
            ...user,
            name: res.name,
            email: res.email,
            phone: res.phone,
          };
          localStorage.setItem("user", JSON.stringify(data));
          dispatch(authActions.login(data));

          reset({ password: "" });
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          nom
        </label>
        <InputCustom
          placeholder={"Entrer votre nom"}
          field={register("name")}
        />
      </div>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          numero de telephone
        </label>
        <InputCustom
          placeholder={"Entrer votre numero de telephone"}
          field={register("phone")}
        />
      </div>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          email
        </label>
        <InputCustom
          placeholder={"Entrer votre email"}
          type="email"
          field={register("email")}
        />
      </div>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          Mot de passe actuel
        </label>
        <InputCustom
          placeholder={"Entrer votre mot de passe actuel"}
          type="password"
          field={register("password")}
        />
      </div>

      <div className="flex justify-end items-center mt-4 space-x-3">
        <button
          onClick={() => reset()}
          className="text-secondary-100 border border-secondary-100 rounded-lg py-2 px-8"
        >
          annuler
        </button>
        <button
          type="submit"
          className="text-white bg-secondary-100 rounded-lg py-2 px-8"
        >
          {loading ? (
            <div className="mt-1">
              <Spinner
                thickness="1px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            </div>
          ) : (
            " sauvegarder"
          )}
        </button>
      </div>
    </form>
  );
};

export default MyInfoForm;
