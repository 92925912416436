import React, { useState } from "react";
import { Select } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import partenaireService from "../../services/partenaireService";
import { useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import dataWilaya from "../../data/Wilaya_Of_Algeria.json";

const DevenirPartenaireForm = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // add new data
  const { register, handleSubmit, reset, setValue, getValues } = useForm({});

  const onSubmit = (data) => {
    setLoading(true);
    partenaireService
      .send(data)
      .then((res) => {
        toast({
          title: "Devenir partenaire.",
          description: "Votre message a ete bien envoyer.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        reset();
      })
      .catch((err) => {
        toast({
          title: "Error.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register("raison", { required: true })}
        type="text"
        placeholder="Raison Sociale *"
        className="InputCustom"
      />
      <input
        {...register("email", { required: true })}
        type="email"
        placeholder="Email *"
        className="InputCustom"
      />
      <input
        {...register("sector", { required: true })}
        type="text"
        placeholder="Secteur d'activite *"
        className="InputCustom"
      />
      <input
        {...register("phone", { required: true })}
        type="tel"
        placeholder="Numero mobile *"
        className="InputCustom"
      />
      <Select
        placeholder="Wilaya *"
        size={"md"}
        {...register("wilaya", { required: true })}
      >
        {dataWilaya.map((item) => (
          <option key={item.id} value={item.code}>
            {item.code} - {item.name}
          </option>
        ))}
      </Select>
      <textarea
        cols="30"
        rows="10"
        className="InputCustom h-[200px]"
        placeholder="Votre message *"
        {...register("message")}
      ></textarea>
      <button
        type="submit"
        className="bg-secondary-100 text-white w-full py-2 font-medium capitalize rounded-lg text-xs md:text-base"
      >
        {loading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          "valider"
        )}
      </button>
    </form>
  );
};

export default DevenirPartenaireForm;
