import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import { Icons } from "../constants";
import { Link, useLocation } from "react-router-dom";

const PathBar = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");
  const cat = queryParams.get("cat");

  let paths = [];

  return (
    <div>
      <Breadcrumb
        spacing="8px"
        separator={<img src={Icons.ArrowRight2} className="w-4 text-400" />}
      >
        <BreadcrumbItem>
          <Link to={"/"}>Accueil</Link>
        </BreadcrumbItem>
        {location.pathname.includes("products") && (
          <BreadcrumbItem>
            <Link to={"/products"}>Tous</Link>
          </BreadcrumbItem>
        )}
        {location.pathname.includes("courses") && (
          <BreadcrumbItem>
            <Link to={"/courses"}>E-learning</Link>
          </BreadcrumbItem>
        )}
        {location.pathname.includes("promotions") && (
          <BreadcrumbItem>
            <p>Tous les promotions</p>
          </BreadcrumbItem>
        )}
        {query && (
          <BreadcrumbItem>
            <p className="text-sm">recherche: {query}</p>
          </BreadcrumbItem>
        )}
        {cat && (
          <BreadcrumbItem>
            <p className="text-sm">categorie: {cat}</p>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
};

export default PathBar;
