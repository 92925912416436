import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useNavigate,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import DevenirPartenairePage from "./pages/DevenirPartenairePage";
import CartPage from "./pages/CartPage";
import ProductsPage from "./pages/ProductsPage";
import ScrollToTop from "./layout/ScrollToTop";
import ProductDetailPage from "./pages/ProductDetailPage";
import AboutPage from "./pages/AboutPage";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import ProfilePage from "./pages/ProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import { useDispatch, useSelector } from "react-redux";
import EditPwdPage from "./pages/EditPwdPage";
import PurchasePage from "./pages/PurchasePage";
import PromotionPage from "./pages/PromotionPage";
import SalesPage from "./pages/SalesPage";
import ConditionsPage from "./pages/ConditionsPage";
import CoursesPage from "./pages/CoursesPage";
import CourseDetailPage from "./pages/CourseDetailPage";
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import ErrorPage from "./pages/ErrorPage";
import PaymentPage from "./pages/PaymentPage";

function App() {
  const { t } = useTranslation("global");
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="bg-gray-50">
      <Router>
        <ScrollToTop />
        <Navbar />
        <div className="pages min-h-[600px]">
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="verify/:token" element={<ConfirmEmailPage />} />
            <Route
              exact
              path="sign-up"
              element={!user ? <SignUp /> : <Navigate to={"/"} />}
            />
            <Route
              exact
              path="login"
              element={!user ? <Login /> : <Navigate to={"/"} />}
            />
            <Route exact path="about-us" element={<AboutPage />} />
            <Route
              exact
              path="cart"
              element={user ? <CartPage /> : <Navigate to={"/sign-up"} />}
            />
            <Route
              exact
              path="payment"
              element={user ? <PaymentPage /> : <Navigate to={"/sign-up"} />}
            />
            <Route exact path="sales" element={<SalesPage />} />
            <Route exact path="conditions" element={<ConditionsPage />} />
            <Route
              exact
              path="profile"
              element={user ? <ProfilePage /> : <Navigate to="/sign-up" />}
            >
              <Route exact index element={<EditProfilePage />} />
              <Route exact path="edit-profile" element={<EditProfilePage />} />
              <Route exact path="edit-password" element={<EditPwdPage />} />
              <Route exact path="history" element={<PurchasePage />} />
              <Route exact path="favorites" element={<></>} />
            </Route>
            <Route exact path="products">
              <Route exact index element={<ProductsPage />} />
              <Route exact path=":id" element={<ProductDetailPage />} />
            </Route>
            <Route exact path="courses">
              <Route exact index element={<CoursesPage />} />
              <Route exact path=":id" element={<CourseDetailPage />} />
            </Route>
            <Route exact path="promotions">
              <Route exact index element={<PromotionPage />} />
            </Route>
            <Route
              exact
              path="/devenir-partenaire"
              element={<DevenirPartenairePage />}
            />
            <Route exact path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
