import React from "react";
import { Icons, Images } from "../../constants";
import Ratings from "./Ratings";
import CircleButton from "./CircleButton";

const ProductCard = ({ item, type = 1, action }) => {
  const finalPrice = item.price - (item.price * item.discount) / 100;

  return (
    <div className="shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] p-2 rounded-lg bg-gray-50 transition-all duration-500 hover:-translate-y-5 mt-6">
      <div className="w-full md:w-full h-[250px] relative group z-0 bg-white rounded-xl overflow-hidden">
        <img
          src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${
            item.images[item.mainImage]
          }`}
          alt={item.name}
          className="rounded-lg w-full h-full object-cover"
        />
        <div
          className={`absolute right-2 top-2 space-y-[4px] ${
            type === 2 && "hidden"
          }`}
        >
          <CircleButton icon={Icons.Love} name={"icon love"} bg="bg-gray-100" />
          <CircleButton
            icon={Icons.Eye}
            name={"icon show"}
            bg="bg-gray-100"
            onClick={() => action(item)}
          />
        </div>
        <div className={`absolute left-2 top-2 ${type === 2 && "hidden"}`}>
          {item.discount > 0 && (
            <p className="bg-third-100 px-2 py-1 text-white rounded-full text-xs ">
              -{item.discount}%
            </p>
          )}
        </div>
        {/* <div className="bg-black absolute bottom-0 left-0 right-0 hidden group-hover:block font-medium cursor-pointer">
          <p className="text-white text-center capitalize mx-auto py-2">
            add to cart
          </p>
        </div> */}
      </div>
      <div className="mt-4 bg-white p-2 rounded-xl z-20 relative">
        <h4
          className="font-medium text-sm md:text-base cursor-pointer hover:underline"
          onClick={() => action(item)}
        >
          {item.name}
        </h4>
        <div className={`${type === 2 && "flex items-center space-x-2"}`}>
          <div className="flex items-center space-x-2">
            <p className="font-medium text-third-100 text-xs md:text-sm">
              {finalPrice} DZD
            </p>
            {item.discount > 0 && (
              <p className="line-through text-gray-400 font-medium text-xs md:text-sm">
                {item.price} DZD
              </p>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <Ratings nbr={item.rate ?? 0} />
            <p className="font-semibold text-gray-400 text-xs md:text-sm">
              ({item.reviews ?? 0})
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
