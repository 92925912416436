import React, { useState } from "react";
import { Icons } from "../../constants";
import InputCustom from "../ui/InputCustom";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { filterActions } from "../../store/filter/filter-slice";

const SearchForm = () => {
  const query = useSelector((state) => state.filter.query);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      query: "",
    },
  });

  const onSubmit = (data) => {
    if (data.search) {
      navigate(`/products?query=${data.search}`);
    } else navigate("/products");
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputCustom
        icon={Icons.Search}
        nameIcon={"icon search"}
        placeholder={"recherche..."}
        css={""}
        autoComplete="off"
        field={register("search")}
      />
    </form>
  );
};

export default SearchForm;
