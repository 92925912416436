import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { productActions } from "../store/product/product-slice";
import { useDispatch, useSelector } from "react-redux";
import { filterActions } from "../store/filter/filter-slice";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // Scroll to the top of the page when the pathname changes
    window.scrollTo(0, 0);

    // clear data
    dispatch(productActions.clearData());
    dispatch(filterActions.clearData());
  }, [pathname]);

  return null;
}

export default ScrollToTop;
