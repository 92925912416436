import React from "react";
import { Images } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../store/product/product-slice";

const OverviewProductSection = ({
  subTitle,
  title,
  color,
  bgColor,
  data,
  onClick = null,
}) => {
  const positions = [
    "col-span-1 row-span-1 md:col-span-6 md:row-span-4",
    "col-span-1 row-span-1 md:col-span-3 md:row-span-2",
    "col-span-1 row-span-1 md:col-span-3 md:row-span-2",
    "col-span-1 row-span-1 md:col-span-6 md:row-span-2",
    "col-span-1 row-span-1 md:col-span-3 md:row-span-2",
    "col-span-1 row-span-1 md:col-span-6 md:row-span-2",
    "col-span-1 row-span-1 md:col-span-3 md:row-span-2",
  ];

  const dispatch = useDispatch();

  const handleOpen = (item) => {
    dispatch(productActions.replaceData({ item: item, type: "course" }));
  };

  return (
    <div className="my-10 md:my-20">
      <div className="mt-6">
        <h3
          className={`w-fit mb-2 capitalize font-semibold text-xs md:text-sm h-fit mt-[5px] bg-gray-100 rounded-tr-full rounded-br-full px-2 py-[3px] ${color}`}
        >
          {subTitle}
        </h3>
        <div className="flex items-start justify-between">
          <div className="flex justify-between space-x-4">
            <h1 className="font-semibold font-inter text-lg md:text-3xl mb-4">
              {title}
            </h1>
          </div>
          <button
            className={`rounded-lg text-xs md:text-base ${color} hover:bg-secondary-100 border-secondary-100 hover:text-white py-2 px-4 md:px-12 h-fit font-medium border`}
            onClick={onClick}
          >
            Voir tous
          </button>
        </div>

        <div className="grid md:grid-cols-12 md:grid-rows-6 gap-4 w-full h-[800px]">
          {data.map((item, idx) => (
            <div
              className={`overflow-hidden p-4 border rounded-xl shadow-lg cursor-pointer ${positions[idx]}`}
              key={item.id}
              onClick={() => handleOpen(item)}
            >
              <img
                src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.images[0]}`}
                alt={item.name}
                className="h-full w-full object-cover rounded-xl hover:scale-150 transform duration-300 hover:rotate-3"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OverviewProductSection;
