import React from "react";
import { Icons } from "../../constants";

const Ratings = ({ nbr, edit = false, setRate, rate }) => {
  return (
    <div className="flex items-center space-x-[2px]">
      {edit
        ? Array.from(Array(5).keys()).map((item) => (
            <img
              src={item + 1 <= rate ? Icons.StarActive : Icons.Star}
              alt={"icon star active"}
              key={item}
              onClick={() => setRate(item + 1)}
              className={`w-3 md:w-4 cursor-pointer`}
            />
          ))
        : Array.from(Array(5).keys()).map((item) => (
            <img
              src={item + 1 <= nbr ? Icons.StarActive : Icons.Star}
              alt={"icon star active"}
              key={item}
              className={`w-3 md:w-4`}
            />
          ))}
    </div>
  );
};

export default Ratings;
