import React, { useEffect, useState } from "react";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import CartTable from "../components/ui/CartTable";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Radio,
  useDisclosure,
  RadioGroup,
} from "@chakra-ui/react";
import basketService from "../services/basketService";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Icons } from "../constants";
import { Link } from "react-router-dom";
import PaymentForm from "../components/form/PaymentForm";

const PaymentPage = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="">
      <main className="responsive my-10">
        <h1 className="text-4xl capitalize font-bold mb-8">Paiement</h1>
        <PaymentForm />
      </main>
    </div>
  );
};

export default PaymentPage;
