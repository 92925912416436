import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import saleService from "../services/saleService";

const ConditionsPage = () => {
  // const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
  //   "sales",
  //   () => saleService.getAll()
  // );

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (isError) {
  //   return <div>Error: {error.message}</div>;
  // }

  return (
    <div className="responsive my-10">
      <section className="main-content">
        {/*MAIN CONTENT CONTENU PRINCIPALE*/}
        <h1 className="text-3xl font-bold mb-10">
          CONDITIONS GENERALES D’UTILISATION (CGU)
        </h1>
        <article className="article-conditions">
          <h2 className="text-2xl font-bold mb-2">
            Article 1. Editeur du site – Nous contacter
          </h2>
          <p>
            Le site Internet www.idoomarket-at.dz est la propriété d’Algérie
            Télécom spa, au capital social de 115.000.000.000 DA, immatriculée
            au registre de commerce sous le numéro 02 B0018083-16/00 dont son
            siège social sis à route nationale N° 5, cinq maisons, Mohammedia,
            Alger, Algérie.
          </p>
          <p>
            {" "}
            <br />
            Il est impératif que tout Utilisateur prenne connaissance des
            présentes Conditions Générales d’Utilisation (ci-après désignées «
            CGU ») qui définissent les règles d’utilisation de la
            idoomarket-at.dz.
          </p>
          <p>&nbsp; </p>
          <p>
            La simple utilisation du présent site web suppose l'acceptation
            pleine et entière des CGU.
          </p>
          <p>&nbsp; </p>
          <p>
            L’utilisation de idoomarket-at.dz est soumise à l'acceptation pleine
            et sans réserve de toutes les conditions ci-après et de toutes les
            conditions affichées sur le site Web.
          </p>
          <p>&nbsp; </p>
          <p>
            Pour toute information ou problème vous pourrez contacter le centre
            d’appels d’Algérie Télécom (12). Il est conseillé de conserver et/ou
            d’imprimer les présentes conditions.
          </p>
          <br />
          {/*-----------------------------Informations légales------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">Article 2. Objet</h2>
          <p>
            Les présentes conditions générales d’Utilisation (C.G.U) ont pour
            objet, d'une part, d'informer toute personne sur les conditions et
            modalités dans lesquelles Algérie Télécom propose la Commande en
            ligne des Produits Commandés via le Site et, d'autre part, de
            définir les droits et obligations des parties dans le cadre de la
            vente de Produits sur le Site.
          </p>
          <p>&nbsp; </p>
          <p>
            Le fait pour tout Client de Commander un Produit proposé à la vente
            sur le Site emporte acceptation pleine et entière des présentes CGU
            dont le Client reconnaît explicitement avoir pris connaissance
            préalablement à la conclusion d’une Commande.
          </p>
          <p>&nbsp;</p>
          <p>
            Le Client déclare être majeur et avoir la pleine capacité juridique,
            lui permettant de s'engager au titre des CGU ou agir sous la
            supervision et avec l'accord d'une personne ayant l'autorité
            parentale.
          </p>
          <p>&nbsp; </p>
          <p>
            Algérie Télécom se réserve la possibilité de modifier à tout moment
            les CGU. Néanmoins, seules seront applicables à une Commande les CGU
            acceptées par le Client au moment de la conclusion de ladite
            Commande.
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Définitions et Interprétation des termes ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 3. Définitions et Interprétation des termes
          </h2>
          <p>
            « Utilisateur » / « client » désignent l'utilisateur final accédant
            au site Web.
          </p>
          <p>
            {" "}
            <br />« Fournisseurs de services » désignent les prestataires de
            services tiers.
          </p>
          <p>
            {" "}
            <br />
            Les termes autour du pronom personnel « nous » désignent ALGERIE
            TELECOM.
          </p>
          <p>
            {" "}
            <br />
            « Site » désigne idoomarket-at.dz, <br />
            « CGU » désigne les Conditions Générales d’Utilisation, <br />«
            Service » désigne le service offert par idoomarket-at.dz.
          </p>
          <p>&nbsp; </p>
          {/*-----------------------------Objet------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 4. Modalités de Commande
          </h2>
          <p>
            Le Site www.idoomarket-at.dz&nbsp; vous permet de commander une
            sélection de produits d’une large gamme de marque, immédiatement en
            ligne via Internet et la récupération des produits s’effectuent par
            une livraison à domicile, sous condition de disponibilité.
          </p>
          <p>
            <br />
            Lors de l’inscription au site web de ladite Boutique, vous créez un
            mot de passe propre à vous, et vous êtes entièrement responsable de
            toutes les activités liées à votre compte.&nbsp; Vos données
            personnelles inscrites sur notre site seront protégées conformément
            à la loi 18-07 relative à la protection des personnes physiques dans
            le traitement des données à caractère personnel.
          </p>
          <p>&nbsp;</p>
          <p>
            La boutique en ligne d’Algérie Télécom ne vous permet pas de passer
            des commandes spéciales qui consistent notamment en la création d’un
            produit n’existant pas ou plus dans l’interface web, ou en
            l’adaptation ou la personnalisation d’un produit issu des
            collections d’Algérie Télécom, et en la fabrication de ce dernier
            par ladite entreprise. Les présentes Conditions Générales de Vente
            ne s’appliquent donc pas aux commandes spéciales.{" "}
          </p>
          <br />
          {/*-----------------------------Utilisation du site Web------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">Article 5. Prix </h2>
          <p>
            Les prix de vente des Produits, indiqués en Dinars Algérien, sont
            ceux en vigueur au moment de la validation de la Commande Client.{" "}
            <br />
            Une fois la commande passée, le CLIENT peut choisir le moyen de
            paiement qui lui convient le mieux :
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>CIB &amp; EDAHABIA</strong> : Le CLIENT peut payer sa
            commande par carte CIB ou EDAHABIA, à l’étape de la commande il va
            trouver le logo CIB et le Logo d’Algérie poste, il sera redirigé
            vers les plateformes sécurisés de SATIM et Algérie poste.
          </p>
          <p>&nbsp;</p>
          <p>
            Les prix comprennent la taxe sur la valeur ajoutée (TVA) applicable
            au jour de la Commande. Tout changement du taux de TVA applicable
            sera automatiquement répercuté sur le prix des Produits proposés à
            la vente sur le Site.{" "}
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Responsabilité de l’utilisateur  ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 6 : Les conditions et modalités de livraison
          </h2>
          <p>
            Les livraisons sont assurées par les services d’un transporteur, du
            samedi au vendredi, en fonction de l’option choisie par le Client
            lors de validation de sa Commande.
          </p>
          <p>&nbsp;</p>
          <p>
            La livraison s’entend du transfert au Client de la possession
            physique des Produits.
          </p>
          <p>&nbsp;</p>
          <p>
            Les frais de livraison applicables à la Commande sont ceux
            mentionnés sur le Site au moment de la Commande dans la rubrique «
            Frais de Livraison ».
          </p>
          <p>&nbsp;</p>
          <p>
            Lorsque Algérie Telecom se charge de l’acheminement du Produit, le
            risque de perte ou de détérioration du Produit est transféré au
            Client au moment de la Livraison.
          </p>
          <p>&nbsp;</p>
          <p>
            Par exception, le risque est transféré au Client lors de la remise
            du Produit au transporteur lorsque celui-ci est chargé du transport
            par le Client et non par Algérie Telecom.
            <br />
            La Livraison est effectuée à l’adresse de livraison indiquée par le
            Client, étant précisé que celle-ci doit être l’adresse de résidence
            du Client, d’une personne physique de son choix ou d’une personne
            morale (livraison à son entreprise).
          </p>
          <p>&nbsp;</p>
          <p>
            Les prix de vente des Produits peuvent être modifiés par Algérie
            Télécom et/ou les fournisseurs des produits à tout moment. Les prix
            applicables à une Commande sont ceux indiqués au Client lors du
            récapitulatif précédant la conclusion de la Commande par le Client.
          </p>
          <p>&nbsp; </p>
          <p>
            Les frais de livraison et d’autres frais supplémentaires seront
            indiqués au client sur la page panier et avant la finalisation de la
            commande. Ces prix peuvent être modifiés à tous moments, les prix
            affichés ne sont valables qu’au moment de la commande et ne portent
            pas effet pour l’avenir.
          </p>
          <p>&nbsp;</p>
          <p>
            Algérie Telecom se réserve le droit de modifier ses prix à tout
            moment mais les Produits seront facturés sur la base des tarifs en
            vigueur au moment de l’enregistrement et/ou du paiement de la
            Commande, sous réserve de disponibilité.
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Exactitude des données affichées  ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 7. Caractéristique des Produits
          </h2>
          <p>
            Le Client peut, préalablement à toute Commande, prendre connaissance
            sur le Site des caractéristiques essentielles du ou des Produit(s).
          </p>
          <p>&nbsp; </p>
          <p>
            Le Client sélectionne un ou plusieurs Produits parmi les différentes
            catégories proposées sur le Site.
          </p>
          <p>&nbsp; </p>
          <p>
            Les Produits proposés à la vente sur le Site peuvent être Commandés
            dans la limite des stocks disponibles tels qu’ils sont établis par
            Algérie Télécom plusieurs fois par jour.
          </p>
          <p>&nbsp;</p>
          <p>
            La disponibilité des produits est affichée sur le SITE, sur chaque
            fiche produit. Si un produit est indisponible, la mention « Ce
            produit est actuellement en rupture et/ou indisponible. » sera
            affichée avant la confirmation de la commande, automatiquement le
            client ne peut pas le commander ; sauf en cas de disfonctionnement
            du site web, où l’état des stocks n’est pas mis à jour, dans ce cas
            la commande sera annulée et le client sera avisé.{" "}
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Propriété intellectuelle ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">Article 8. Paiement </h2>
          <p>
            Toute Commande est payable en Dinars Algérien. Le paiement
            s’effectue avec la carte <strong>CIB</strong> ou{" "}
            <strong>DAHABIA</strong> via notre Plateforme en ligne.
          </p>
          <p>&nbsp; </p>
          {/*-----------------------------Non-validité partielle  ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 9. Annulation d’une commande{" "}
          </h2>
          <p>
            Algérie Télécom se réserve le droit de limiter ou d’interdire les
            commandes qui, à son seul jugement, pourraient sembler provenir de
            marchands, de revendeurs ou de distributeurs.{" "}
          </p>
          <p>
            {/*-----------------------------Résiliation  ------------------------------------------*/}
          </p>
          <p>&nbsp;</p>
          <h2 className="text-2xl font-bold mb-2">
            Article 10. Réclamation sur une commande de produit
          </h2>
          <h4 className="c-blue">
            Si le client récupère un produit qui ne correspond pas à sa commande
            initiale&nbsp;:{" "}
          </h4>
          <p>
            Ce dernier doit signaler l’erreur au niveau de l’agence commerciale
            la plus proche, dans les 96h ouvrable après l’achat du
            produit&nbsp;; le retour sera gratuit et la deuxième livraison sera
            prise en charge par Algérie Télécom.
          </p>
          <p>&nbsp;</p>
          <h4 className="c-blue">
            Dans le cas où le client récupère un produit défectueux&nbsp;:
          </h4>
          <p>
            &nbsp;Ce dernier doit signaler l’erreur au niveau de l’agence
            commerciale la plus proche dans les 96h ouvrable de l’achat du
            Produit. Après vérification de la défectuosité, un deuxième produit
            sera transmis dans un délais qui ne dépasse les 15 jours après
            réclamation (selon la disponibilité)Ou un remboursement si le
            produit n’est pas disponible après la période mentionnée.
          </p>
          <p>&nbsp; </p>
          <p>
            <strong>NB</strong>&nbsp;: Par ailleurs seules les marchandises
            retournées en parfait état de revente, complètes et dans leur
            emballage d’origine (non-ouvert) pourront être remboursées. Au-delà
            du délai mentionné la vente est ferme et définitive.
          </p>
          <p>
            En ce qui concerne les vouchers, une fois qu'ils ont été envoyés par
            courrier électronique, ils ne sont pas sujets à remboursement.
          </p>
          <p>
            Si un client est absent lors de la livraison de produits et que ces
            derniers sont retournés, les articles demeureront chez Algérie
            Télécom pendant un mois.{" "}
          </p>
          <p>
            Si le client souhaite une seconde livraison pendant ce délai, les
            frais de la deuxième livraison seront à sa charge.
          </p>
          <p>
            Si le délai d'un mois est dépassé, les articles seront réintégrés
            dans le stock, et le client recevra un remboursement uniquement pour
            les produits sans les frais de livraison.
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Prix et promotions   ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 11 : Responsabilité de l’utilisateur
          </h2>
          <p>
            L’utilisateur peut être tenus légalement responsables de ses
            transactions, en particulier, lorsqu’il effectue une opération
            d’achat.
          </p>
          <p>&nbsp;</p>
          <p>
            L’utilisateur reconnaît assumer l’ensemble des risques liés à tout
            contenu téléchargé ou obtenu de toute autre manière par le biais de
            l’utilisation du Site et convient qu’il est seul responsable de tout
            dommage causé à son système informatique ou de toute perte de
            données résultant du téléchargement de ce contenu.
          </p>
          <p>
            Pour bénéficier de la réduction, les clients conventionnés doivent
            effectuer leurs achats dans nos agences commerciales.
          </p>
          <p>
            {/*-----------------------------Commandes et validation   ------------------------------------------*/}
          </p>
          <p>&nbsp;</p>
          <h2 className="text-2xl font-bold mb-2">
            Article 12. Responsabilité d’Algérie Télécom{" "}
          </h2>
          <p>
            Algérie Télécom s'est efforcé de faire en sorte que toutes les
            informations sur le site Web soient correctes, mais Algérie Télécom
            ne garantit pas la qualité, l'exactitude ou l’exhaustivité des
            données, informations, produits ou services, ainsi que le bon
            fonctionnement du site sans interruption ni erreur, A ce titre, il
            est précisé que :
          </p>
          <p>&nbsp; </p>
          <p>
            Le Site est accessible 24 heures sur 24, 7 jours sur 7 sauf cas de
            force majeure ou événement hors du contrôle d’Algérie Télécom.
          </p>
          <p>&nbsp;</p>
          <p>
            Nous pouvons être amenée à interrompre l’accès à tout ou partie du
            Site, à tout ou partie des Utilisateurs, de façon temporaire pour
            des raisons de maintenance visant le bon fonctionnement du Site,
            sans préavis et sans que cette interruption puisse donner droit à
            une quelconque indemnité pour l’Utilisateur.
          </p>
          <p>&nbsp;</p>
          <p>
            L’Utilisateur renonce à rechercher la responsabilité d’Algérie
            Télécom au titre de ces interruptions de service ou perturbations de
            fonctionnement.{" "}
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Disponibilité   ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 13 : Propriété intellectuelle{" "}
          </h2>
          <p>
            Les contenus du site (illustrations graphiques, textes…) sont
            protégés par le Code de la propriété intellectuelle et par le droit
            d’auteur.
          </p>
          <p>&nbsp;</p>
          <p>
            La reproduction et la copie des contenus par l’Utilisateur
            requièrent une autorisation préalable du site.
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Responsabilité de boutique.at.dz ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 14. Protection de donnée à caractère personnel
          </h2>
          <p>
            L’approbation de ce CGU donne le droit à Algérie Télécom de
            collecter les données personnelles des clients.
          </p>
          <p>
            Algérie Télécom collecte des données à caractère personnel relatives
            au Client, à l’Utilisateur consultant le Site, ou au destinataire
            d’une Commande. Cette collecte de données s’effectue notamment :{" "}
          </p>
          <ul className="liste-simple">
            <li>Lorsque l’Utilisateur ou le Client crée un compte ;</li>
            <li>Lorsque le Client effectue une Commande sur le Site ;</li>
            <li>
              Lorsque l’Utilisateur ou le Client contacte le Service Après-Vente
              ;
            </li>
            <li>Lorsque l’Utilisateur ou le Client navigue sur le Site ;</li>
          </ul>
          <p>
            Les données collectées sont destinées à l'usage d’Algérie Télécom.
            Elles sont nécessaires au traitement et à la gestion des Commandes
            du Client.
          </p>
          <p>&nbsp;</p>
          <p>
            En passant Commande sur le Site, le Client peut également être
            informé des offres d’Algérie Télécom, sous forme de newsletters,
            emails événementiels et services fournis par Algérie Télécom.
          </p>
          <p>&nbsp;</p>
          <p>
            Algérie Télécom est responsable du traitement de ces données. Elles
            peuvent être transmises aux Fournisseurs auxquels Algérie Télécom
            fait appel dans le cadre de l’exécution des Commandes et des
            services proposés via les Sites. Ou dans les conditions indiquées
            lors de la collecte des données sur le Site.&nbsp;{" "}
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Article 15. Résiliation – Suspension ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 15. Résiliation – Suspension
          </h2>
          <p>
            Algérie Telecom pourra suspendre le service après information
            préalable du client par tous moyens, si des renseignements erronés
            ont été communiqués lors de l’utilisation du service et sans
            notifications préalables dans les autres cas.
          </p>
          <p>&nbsp;</p>
          <p>
            Le client reconnaît et accepte qu’Algérie Telecom puisse également
            suspendre immédiatement le service.
          </p>
          <p>&nbsp;</p>
          <p>
            En cas d’utilisation frauduleuse ou anormale du service. <br />
            En cas de non-respect du client, Algérie Telecom se réserve le droit
            du suspendre et de refuser l’accès au service.
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Article 16. Cookies ------------------------------------------*/}
          <h2 className="text-2xl font-bold mb-2">Article 16. Cookies </h2>
          <p>
            Les cookies c’est des éléments d’identification déposés sur votre
            ordinateur permettant de collecter des informations non-personnelles
            vous concernant. Des cookies, pour quoi faire ?
          </p>
          <p>&nbsp;</p>
          <p>
            Lors de la consultation de site www.idoomarket-at.dz, des cookies
            peuvent être utilisés.
          </p>
          <p>&nbsp;</p>
          <p>
            Certains de nos cookies sont nécessaires pour que nous puissions
            vous fournir le service que vous avez demandé et personnaliser les
            services de notre site.
          </p>
          <p>&nbsp;</p>
          <p>
            D’autres cookies nous permettent de mesurer l’audience rencontrée
            par notre site. Les cookies que nous utilisons sur ce site ne
            stockent pas d’informations personnelles vous concernant (adresse,
            numéro de téléphone mobile, mot de passe, etc.).
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Article 17. Loi applicable et règlements des différends  -----------------*/}
          <h2 className="text-2xl font-bold mb-2">
            Article 17. Loi applicable et règlements des différends{" "}
          </h2>
          <p>
            Les présentes conditions de payement en ligne sont soumises aux lois
            algériennes. Tout litige résultant de l’exécution de
            l’interprétation des présentes conditions de payement en ligne peut
            rapporter devant la juridiction algérienne territorialement
            compétente.{" "}
          </p>
          <p>&nbsp;</p>
          {/*-----------------------------Article 18. Modification-----------------*/}
          <h2 className="text-2xl font-bold mb-2">Article 18. Modification</h2>
          <p>
            Ces Conditions Générales d’Utilisation peuvent changer à tout moment
            par exemples en incluant ou excluant des clauses.
          </p>
          <p>&nbsp; </p>
          <p>
            Algérie Télécom se réserve le droit de mettre à jour, de changer ou
            de remplacer toute partie de ces Conditions Générales d’Utilisation
            en publiant les mises à jour et/ou les modifications sur le site
            web.
          </p>
          <p>&nbsp;</p>
          <p>
            Toutes les nouvelles fonctionnalités et tous les nouveaux outils qui
            seront ajoutés ultérieurement à cette boutique seront également
            assujettis à ces Conditions Générales d’Utilisation. Ces
            modifications entreront en vigueur dès leur mise en ligne sur le
            Site et seront donc opposables à tout internaute dès cet instant.
          </p>
          <p>&nbsp; </p>
          <p>
            Le client peut consulter la version la plus récente des Conditions
            Générales d’Utilisation à tout moment sur cette page ; il lui
            incombe de consulter cette page régulièrement pour vérifier si des
            modifications ont été apportées.
          </p>
          <p>&nbsp; </p>
          <p>
            L’utilisation continue du site web ou l’accès à celui-ci après la
            publication de toute modification constitue une acception de la part
            du client de ces modifications.
          </p>
          <p>&nbsp;</p>
        </article>
      </section>
    </div>
  );
};

export default ConditionsPage;
