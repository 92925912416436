import React, { useEffect, useState } from "react";
import InputCustom from "../ui/InputCustom";
import userService from "../../services/userService";
import { authActions } from "../../store/auth/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

const MyPwdForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const { register, handleSubmit, reset, values } = useForm({
    defaultValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError(
        "nouveau mot de passe & mot de passe confirmer ne sont pas les memes"
      );
      return;
    }
    setError("");
    if (data.password && data.newPassword === data.confirmPassword) {
      setLoading(true);
      userService
        .updatePwd(user.token, data)
        .then((res) => {
          reset();
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          mot de passe actuel
        </label>
        <InputCustom
          type="password"
          placeholder={"Entrer votre mot de passe actuel"}
          field={register("password", { required: true })}
        />
      </div>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          nouveau mot de passe
        </label>
        <InputCustom
          type="password"
          placeholder={"Entrer nouveau mot de passe"}
          field={register("newPassword", { required: true })}
        />
      </div>
      <div className="">
        <label className="text-sm capitalize text-gray-400" htmlFor="">
          confirm password
        </label>
        <InputCustom
          placeholder={"Confirmer votre nouveau mot de passe"}
          type="password"
          field={register("confirmPassword", { required: true })}
        />
      </div>
      {error && (
        <p className="text-sm text-red-500 border border-red-500 rounded-md px-4 py-1 bg-red-100">
          {error}
        </p>
      )}
      <div className="flex justify-end items-center mt-4 space-x-3">
        <button
          onClick={() => reset()}
          className="text-secondary-100 border border-secondary-100 rounded-lg py-2 px-8"
        >
          annuler
        </button>
        <button
          type="submit"
          className="text-white bg-secondary-100 rounded-lg py-2 px-8"
        >
          {loading ? (
            <div className="mt-1">
              <Spinner
                thickness="1px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            </div>
          ) : (
            "sauvegarder"
          )}
        </button>
      </div>
    </form>
  );
};

export default MyPwdForm;
