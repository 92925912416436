import React from "react";
import { Icons } from "../../constants";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  PopoverArrow,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { filterActions } from "../../store/filter/filter-slice";
import { useDispatch, useSelector } from "react-redux";

const PopoverCustom = ({ item, type = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGo = (id, type = "") => {
    if (type === "course") navigate(`/courses?cat=${id}`);
    else navigate(`/products?cat=${id}`);
  };

  return (
    <div className="w-full">
      <Popover placement="right">
        <PopoverTrigger>
          <button className="flex items-center justify-between w-full">
            <p className="capitalize">{item.title}</p>
            <img src={Icons.ArrowRight2} alt="icon arrow right" />
          </button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <ul className="px-4">
            {item.sub.map((subItem, idx) => (
              <li
                key={subItem.id}
                className={`capitalize cursor-pointer py-2 ${
                  idx < item.sub.length - 1 && "border-b"
                }`}
                onClick={() => handleGo(subItem.id, type)}
              >
                {subItem.name}
              </li>
            ))}
          </ul>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default PopoverCustom;
