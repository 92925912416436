import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: {
    item: null,
    type: "",
  },
  reducers: {
    replaceData(state, action) {
      state.item = action.payload.item;
      state.type = action.payload.type;
    },
    clearData(state, action) {
      state.item = null;
      state.type = "";
    },
  },
});

export const productActions = productSlice.actions;

export default productSlice;
