import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const getAll = () => {
  return axios
    .get(`${url}/sales`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const get = (id) => {
  return axios
    .get(`${url}/sales/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

export default {
  getAll,
  get,
};
