import React, { useEffect, useState } from "react";
import dataProducts from "../data/products.json";
import ProductCard from "../components/ui/ProductCard";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import PathBar from "../layout/PathBar";
import { useLocation, useParams } from "react-router-dom";
import DetailsPopUp from "../components/ui/DetailsPopUp";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../store/product/product-slice";
import productsService from "../services/productsService";
import { useQuery } from "react-query";
import CartDrawer from "../components/ui/CartDrawer";
import { filterActions } from "../store/filter/filter-slice";
import basketService from "../services/basketService";
import { cartActions } from "../store/cart/cart-slice";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { Images } from "../constants";

const ProductsPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");
  const cat = queryParams.get("cat");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  const [select, setSelect] = useState(null);
  const dispatch = useDispatch();
  const productTypeSlice = useSelector((slice) => slice.product.type);
  const productSlice = useSelector((slice) => slice.product.item);

  useEffect(() => {
    if (productTypeSlice && productSlice) onOpen();
  }, [productTypeSlice]);

  const clearData = () => {
    onClose();
    dispatch(productActions.replaceData({ item: null, type: "" }));
  };

  const handleOpen = (item) => {
    dispatch(productActions.replaceData({ item: item, type: "product" }));
  };

  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "products",
    () => productsService.getAll({ query: query, cat: cat })
  );

  useEffect(() => {
    refetch();
  }, [query, cat]);

  const user = useSelector((state) => state.auth.user);

  const {
    isLoading: isLoadingCart,
    data: dataCart,
    isError: isErrorCart,
    error: errorCart,
    isFetching: isFetchingCart,
    refetch: refetchCart,
  } = useQuery(
    "my-basket",
    () => {
      if (user) return basketService.get(user.token);
      else return [];
    },
    {
      onSuccess: (data) => {
        if (data && data.products && data.courses) {
          const res = [...data.products, ...data.courses];
          dispatch(cartActions.replaceData(res));
        }
      },
    }
  );

  useEffect(() => {
    refetchCart();
  }, [user]);

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="my-10 responsive">
        <PathBar />
        <div className="grid md:grid-cols-4 gap-4">
          {isLoading ? (
            [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <div key={item}>
                <div className="shadow-md p-2 rounded-lg bg-gray-50 transition-all duration-500 hover:-translate-y-5 mt-6">
                  <div className="w-full md:w-full h-[280px] relative group z-0 bg-white rounded-xl overflow-hidden">
                    <Skeleton width={250} height={280} />
                  </div>
                  <div className="mt-4 bg-white p-2 rounded-xl z-20 relative">
                    <SkeletonText />
                  </div>
                </div>
              </div>
            ))
          ) : data.length > 0 ? (
            data.map((item, idx) => (
              <ProductCard item={item} key={idx} action={handleOpen} />
            ))
          ) : (
            <div className="flex items-center justify-center col-span-4">
              <img src={Images.NoData} alt="" className="w-80" />
            </div>
          )}
        </div>
      </div>

      <DetailsPopUp
        isOpen={isOpen}
        onClose={onClose}
        onOpenCart={onOpenCart}
        clearData={clearData}
        productTypeSlice={productTypeSlice}
      />
      {!isLoadingCart && (
        <CartDrawer
          isOpen={isOpenCart}
          onClose={onCloseCart}
          onOpen={onOpenCart}
        />
      )}
    </div>
  );
};

export default ProductsPage;
