import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const register = (data) => {
  return axios
    .post(`${url}/register`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

const login = (data) => {
  return axios
    .post(`${url}/login`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

const verify = (token) => {
  return axios
    .get(`${url}/verify/${token}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

const isVerify = (token) => {
  return axios
    .get(`${url}/is-verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export default {
  register,
  login,
  verify,
  isVerify,
};
