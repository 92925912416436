import React, { useEffect, useRef, useState } from "react";
import dataProducts from "../data/products.json";
import ProductCard from "../components/ui/ProductCard";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import PathBar from "../layout/PathBar";
import { useLocation, useParams } from "react-router-dom";
import DetailsPopUp from "../components/ui/DetailsPopUp";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../store/product/product-slice";
import CardSection from "../components/section/CardSection";
import { Icons, Images } from "../constants";
import SwiperCustom from "../components/ui/SwiperCustom";
import CommentBox from "../layout/CommentBox";
import productsService from "../services/productsService";
import { useQuery } from "react-query";
import coursesService from "../services/coursesService";
import Ratings from "../components/ui/Ratings";
import { Spinner } from "@chakra-ui/react";
import basketService from "../services/basketService";
import { cartActions } from "../store/cart/cart-slice";
import CartDrawer from "../components/ui/CartDrawer";

const CourseDetailPage = () => {
  const swiperImgs = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  // get products
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "course",
    () => coursesService.get(id)
  );

  // get products
  const {
    isLoading: isLoadingSimilair,
    data: dataSimilair,
    isError: isErrorSimilair,
    error: errorSimilair,
    isFetching: isFetchingSimilair,
    refetch: refetchSimilair,
  } = useQuery("course-similair", () => coursesService.getSimilar(id));

  const {
    isLoading: isLoadingCart,
    data: dataCart,
    isError: isErrorCart,
    error: errorCart,
    isFetching: isFetchingCart,
    refetch: refetchCart,
  } = useQuery(
    "my-basket",
    () => {
      if (user) return basketService.get(user.token);
      else return [];
    },
    {
      onSuccess: (data) => {
        if (data && data.products && data.courses) {
          const res = [...data.products, ...data.courses];
          dispatch(cartActions.replaceData(res));
        }
      },
    }
  );

  useEffect(() => {
    refetchCart();
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddToCart = () => {
    setLoading(true);
    basketService
      .addItem(user.token, {
        type: "course",
        item: id,
      })
      .then((res) => {
        dispatch(cartActions.addData(res));
        setLoading(false);
        onOpenCart();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="my-10 responsive">
        <PathBar />
      </div>

      <div className="responsive">
        {/* detail product  */}
        <div className="flex flex-col md:grid md:grid-cols-12 gap-x-4 border-b">
          <div className="col-span-7 h-[500px]">
            <img
              src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${data.images[0]}`}
              alt={""}
              className="w-full h-full object-cover"
            />
          </div>
          {/* start details  */}
          <div className="bg-gray-100 h-full col-span-5 px-4 pt-4 md:pt-8 pb-4 flex flex-col justify-between">
            {/* start general information  */}
            <div>
              <h1 className="capitalize text-lg md:text-2xl font-bold">
                {data.name}
              </h1>
              <h3 className="capitalize mb-2">
                E-learning / {data.category.name}
              </h3>
              <div className="items-center flex space-x-3">
                <Ratings nbr={data.rate} />
                <p className="text-xs text-gray-400">
                  ({data.reviews} commentaires)
                </p>
              </div>
              <div className="flex items-center space-x-2 mt-2 justify-between">
                {/* <p className="capitalize mb-4 text-sm">price:</p> */}
                <div className="space-x-2 flex items-center">
                  <p
                    className={`${
                      data.discount > 0
                        ? "line-through text-gray-400"
                        : "text-third-100"
                    } font-medium text-sm`}
                  >
                    {data.price} DZD
                  </p>
                  {data.discount > 0 && (
                    <p className="text-third-100 font-medium text-sm">
                      {(1 - data.discount / 100) * data.price} DZD
                    </p>
                  )}
                </div>
                <img src={Icons.Love} alt="love icon" />
              </div>
              {/* start chapters  */}
              <h4 className="font-medium mt-6 text-gray-500 text-sm">
                Description
              </h4>
              <div className="bg-gray-600 text-gray-100 rounded-lg px-4 py-3 mt-3">
                <p className="text-xs">{data.description}</p>
              </div>
            </div>
            {/* buttons  */}
            <div className="w-full grid md:grid-cols-2 gap-x-4 text-sm">
              <button className="w-full bg-secondary-100 text-white rounded-lg mt-6 mx-auto capitalize px-4 py-3">
                <div className="flex items-center space-x-2 justify-center">
                  <p>manuel</p>
                  <img src={Icons.Manuel} alt="" className="w-6" />
                </div>
              </button>
              <button
                onClick={handleAddToCart}
                className="w-full bg-primary-100 text-white rounded-lg mt-6 mx-auto capitalize px-4 py-3"
              >
                {loading ? (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <div className="flex items-center space-x-2 justify-center">
                    <p>Ajouter au panier</p>
                    <img src={Icons.CartWhite} alt="" className="w-6" />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* caracteristiqeus  */}
        <div className="mt-16">
          <h1 className="font-semibold font-inter text-lg md:text-3xl mb-6">
            Caracteristiques
          </h1>
          <ul className="space-y-2">
            {data.features.map((feature) => (
              <li key={feature.key}>
                <span className="font-medium first-letter:capitalize">
                  {feature.key}:
                </span>{" "}
                <span>{feature.value}</span>
              </li>
            ))}
          </ul>
        </div>
        {/* similar product */}
        {isLoadingSimilair ? (
          <></>
        ) : (
          dataSimilair &&
          dataSimilair.length > 0 && (
            <div className="">
              <CardSection
                subTitle={""}
                title={"Similar Products"}
                color={"text-primary-100"}
                bgColor={"bg-primary-100"}
                data={dataSimilair}
                card={ProductCard}
                cssContainer={"py-10"}
                hide
              />
            </div>
          )
        )}
        {/* comments  */}
        {user && (
          <div className="mt-4 mb-10">
            <CommentBox itemId={{ courseId: id }} />
          </div>
        )}
      </div>
      {!isLoadingCart && (
        <CartDrawer
          isOpen={isOpenCart}
          onClose={onCloseCart}
          onOpen={onOpenCart}
        />
      )}
    </div>
  );
};

export default CourseDetailPage;
