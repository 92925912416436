import React from "react";

const InputCustom = ({
  icon,
  nameIcon,
  placeholder,
  css,
  field,
  autoComplete = "on",
  type = "text",
}) => {
  return (
    <div
      className={`py-[7px] px-[12px] rounded-lg ${css} flex items-center justify-between border-gray-200 border`}
    >
      <input
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        name="search"
        className="bg-transparent mr-6 pr-2 outline-none w-full"
        {...field}
      />
      <button type="submit">
        <img src={icon} alt={nameIcon} className="w-5" />
      </button>
    </div>
  );
};

export default InputCustom;
